import React from 'react'
import { withRouter } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))
const Maintainance = ({ history, projectLists }) => {
  const classes = useStyles()

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <div class="flex flex-col justify-center items-center w-screen h-screen">
          <h1 class="text-center text-2xl font-bold">ただいまメンテナンス中です</h1>
          <p class="text-center mt-4">ご利用の皆様にはご迷惑をおかけし、申し訳ございません。<br />メンテナンス終了までしばらくお待ちください。</p>
        </div>
      </div>
    </Container>
  )
}

export default withRouter(Maintainance)