import { baseUrl, ISSUE_CUSTOM_FIELD } from '../../constants'
export default async function postReportApi(
  images,
  coords,
  selectedProjectId,
  selectedTrackerId,
  title,
  description,
  apiKey,
  checkedThemeId,
  trackerName,
  mail,
  phoneNumber,
  address,
  currentAddress,
  nickName
) {
  const geom = {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [coords.longitude, coords.latitude]
    },
    properties: null
  }
  const requestUrl = baseUrl + '/issues.json'
  const postResponse = await fetch(requestUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Redmine-API-Key': apiKey
    },
    body: JSON.stringify({
      issue: {
        project_id: selectedProjectId,
        subject: title,
        description: description,
        geojson: JSON.stringify(geom),
        custom_fields: [
          {
            id: ISSUE_CUSTOM_FIELD.REPORT_MEANS.id,
            value: 'MCR'
          },
          {
            id: ISSUE_CUSTOM_FIELD.CURRENT_ADDRESS.id,
            value: currentAddress
          },
          {
            id: ISSUE_CUSTOM_FIELD.REPORTER_MAIL_ADDRESS.id,
            value: mail
          },
          {
            id: ISSUE_CUSTOM_FIELD.REPORTER_TEL.id,
            value: phoneNumber
          },
          {
            id: ISSUE_CUSTOM_FIELD.REPORTER_ADDRESS.id,
            value: address
          },
          {
            id: ISSUE_CUSTOM_FIELD.NICK_NAME.id,
            name: 'ニックネーム',
            value: nickName
          }
        ],
        uploads: images,
        tracker_id: selectedTrackerId,
        fixed_version_id: `${
          trackerName === 'テーマレポート' ? checkedThemeId : ''
        }`
      }
    })
  })
  return postResponse
}
