import isAvailable from '../../lib/isAvailable'

export default function sortIssuesByUpdatedOn(suppoterTypeExcludedIssues) {
  if (!isAvailable(suppoterTypeExcludedIssues)) return
  const sortedIssues = suppoterTypeExcludedIssues.sort((a, b) => {
    if (a.updated_on > b.updated_on) return -1
    if (a.updated_on < b.updated_on) return 1
    return 0
  })
  return sortedIssues
}
