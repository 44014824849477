import React, { useState, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import deriveValueFromCustomFields from '../../lib/deriveValueFromCustomFields'

const EmergContact = ({ history, mainProject }) => {
  const customFields = mainProject.project.custom_fields
  const rawEmergContact = deriveValueFromCustomFields(customFields, "緊急連絡先情報")
  const emergContactJson = JSON.parse(rawEmergContact)

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Typography variant="h5" component="h6" style={{ fontWeight: 'bold', marginTop: '20px' }}>
        {emergContactJson.name}
      </Typography>
      <Typography style={{ marginTop: '15px', marginBottom: '15px' }}>
        緊急な処理が必要な場合には、
        レポートではなく管轄する自治体まで直接お電話にて ご連絡ください。
      </Typography>
      {emergContactJson.categories.map((category, i) => (
        <Container key={i}>
          <Typography variant="h6" component="h6" style={{ fontWeight: 'bold' }}>{category.name}</Typography>
          <Typography variant="body1" style={{ marginTop: '8px', marginBottom: '8px', whiteSpace: 'pre-line' }}>{category.description}</Typography>
          {category.sections.map((section, j) => (
            <Container key={j}>
              <Typography variant="h6" component="h6" style={{ fontWeight: 'bold' }}>{section.name}</Typography>
              {section.label !== undefined ? (
                <List style={{ display: 'flex' }}>
                  <ListItem>{section.label.name}</ListItem>
                  <ListItem>{section.label.description}</ListItem>
                </List>
              ) : (
                <Fragment />
              )}
              {section.phoneNumbers !== undefined ? (
                section.phoneNumbers.map((phone, k) => (
                  <Grid col={12} key={k}>
                    <List style={{ display: 'flex' }}>
                      <ListItem>
                        {phone.name}
                      </ListItem>
                      {phone.phoneNumber !== '' && (
                      <ListItemLink style={{ color: '#6495ed' }}>
                        {phone.phoneNumber}
                      </ListItemLink>
                      )}
                    </List>
                  </Grid>
                ))
              ) : (
                <Fragment />
              )}
            </Container>
          ))}
        </Container>
      ))}
    </Container>
  )
}

export default withRouter(EmergContact)

