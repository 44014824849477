import { baseUrl, USER_CUSTOM_FIELD } from '../../constants'
import deriveFieldIdOfBirthYear from '../../domains/auth/deriveFieldIdOfBirthYear'

export default async function updateUserApi(
  apiKey,
  displayName,
  firstName,
  lastName,
  phoneNumber,
  mainProject,
  address,
  gender,
  occupation,
  birthYear
) {
  const requestUrl = baseUrl + '/my/account.json'
  const response = await fetch(requestUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Redmine-API-Key': apiKey
    },
    body: JSON.stringify({
      user: {
        firstname: firstName,
        lastname: lastName,
        displayname: displayName,
        custom_fields: [
          {
            id: USER_CUSTOM_FIELD.PHONE_NUMBER.id,
            value: phoneNumber
          },
          {
            id: USER_CUSTOM_FIELD.ADDRESS.id,
            value: address
          },
          {
            id: USER_CUSTOM_FIELD.GENDER.id,
            value: gender
          },
          {
            id: USER_CUSTOM_FIELD.OCCUPATION.id,
            value: occupation
          },
          {
            id: USER_CUSTOM_FIELD.MAIN_PROJECT.id,
            value: mainProject
          },
          {
            id: `${deriveFieldIdOfBirthYear(baseUrl)}`,
            value: birthYear
          }
        ]
      }
    })
  })
  return response
}
