import { baseUrl } from '../../constants'

export default async function fetchCitizenReporterRoleId() {
  const roleUrl = `${baseUrl}/roles.json`
  const roleResult = await fetch(roleUrl)
  const roleJson = await roleResult.json()
  const roles = roleJson.roles
  let roleId = 0
  roles.map(role => {
    if (role.name === '市民レポーター') {
      roleId = role.id
    }
  })
  return roleId
}
