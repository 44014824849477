import React from 'react'
import { Grid, Button } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
}))

const SubHeader = (props) => {
  const {
    title,
    buttonTitleLeft,
    buttonTitleMiddle,
    buttonTitleRight,
    toggleLeftButton,
    toggleMiddleButton,
    toggleRightButton,
  } = props
  const classes = useStyles()

  return (
    <div className={classes.heroContent}>
      <Container maxWidth="sm">
        <Typography
          component="h4"
          variant="h4"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          {title}
        </Typography>
        <div className={classes.heroButtons}>
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Button
                variant={title === 'レポート一覧' ? 'contained' : 'outlined'}
                color="primary"
                onClick={() => {
                  toggleLeftButton()
                }}
              >
                {buttonTitleLeft}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant={title === '地図表示' ? 'contained' : 'outlined'}
                color="primary"
                onClick={() => {
                  toggleMiddleButton()
                }}
              >
                {buttonTitleMiddle}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  toggleRightButton()
                }}
              >
                {buttonTitleRight}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  )
}

export default SubHeader
