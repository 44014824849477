import isAvailable from '../../lib/isAvailable'

export default function excludeTokyoRegion(allIssues) {
  if (!isAvailable(allIssues)) return
  const filteredIssues = allIssues.filter(
    (issue) =>
       !issue.project.name.includes('千代田区') &&
       !issue.project.name.includes('港区') &&
       !issue.project.name.includes('品川区') &&
       !issue.project.name.includes('目黒区') &&
       !issue.project.name.includes('大田区') &&
       !issue.project.name.includes('練馬区') &&
       !issue.project.name.includes('葛飾区') &&
       !issue.project.name.includes('八王子') &&
       !issue.project.name.includes('国分寺') &&
       !issue.project.name.includes('国立') &&
       !issue.project.name.includes('東京') &&
       !issue.project.name.includes('福生') &&
       !issue.project.name.includes('武蔵村山') &&
       !issue.project.name.includes('警視庁') &&
       !issue.project.name.includes('多摩') &&
       !issue.project.name.includes('稲城') &&
       !issue.project.name.includes('神奈川') &&
       !issue.project.name.includes('お試し') &&
       !issue.project.name.includes('運用未実施') &&
       !issue.project.name.startsWith("13")
  )
  return filteredIssues
}
