import moment from 'moment'
import deriveValueFromCustomFields from '../../lib/deriveValueFromCustomFields'
import Comment from './Comment'

const Issue = obj => ({
  id: obj.id,
  author: obj.author,
  title: obj.subject,
  description: obj.description,
  createdOn:
    obj.created_on === null
      ? null
      : moment(obj.created_on).format('YYYY-MM-DD'),
  customFields: obj.custom_fields,
  geojson: obj.geojson,
  isPrivate: obj.is_private,
  priority: obj.priority,
  project: obj.project,
  startDate: obj.start_date === null ? null : obj.start_date,
  status: obj.status,
  tracker: obj.tracker,
  updatedOn:
    obj.updated_on === null
      ? null
      : moment(obj.updated_on).format('YYYY-MM-DD'),
  attachments: obj.attachments === null ? null : obj.attachments,
  nickname: deriveValueFromCustomFields(obj.custom_fields, 'ニックネーム'),
  comments: obj.journals === undefined ? null : obj.journals.filter((journal) => journal.notes !== '')
  .map(
    (journal) =>
      new Comment(
        journal.notes
      )
  )
})

export const dummyIssue = {
  id: -1,
  author: "",
  title: "",
  description: "",
  createdOn: '2020-11-30',
  customFields: [],
  geojson: "",
  isPrivate: false,
  project: {id: -1, name: ""},
  status: {id: -1, name: ""},
  tracker: {id: -1, name: ""},
  attachments: [],
  nickname: "",
  comments: []
}

export const generateShapedIssues = issues => {
  if (issues === null || issues === undefined) return
  const Issues = issues.map(issue => Issue(issue))
  return Issues
}
