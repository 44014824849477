import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
    Box,
    Modal
} from '@material-ui/core'

import generateShapedComments from '../../models/report/generateShapedComments'
import Thumbnail from '../map/Thumbnail'
import CommentsField from '../../modules/CommentsField'
import ReportMap from '../../modules/ReportMap'
import Title from '../../modules/Title'
import Description from '../../modules/Description'
import deriveValueFromCustomFields from '../../lib/deriveValueFromCustomFields'

const useStyles = makeStyles(theme => ({
  table: {
    width: 650
  },
  tableHead: {
    display: 'block',
    float: 'left'
  },
  tableBody: {
    display: 'block',
    float: 'left'
  },
  tableCellinHead: {
    display: 'block',
    width: 200,
    height: '50px',
    paddingRight: '14px',
    border: 'none'
  },
  tableCellinHeadDescription: {
    display: 'flex',
    width: 200,
    height: '100px',
    paddingRight: '14px',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableCellinBody: {
    display: 'block',
    width: 450,
    height: '50px',
    border: 'none',
    textAlign: 'left'
  },
  tableCellinBodyDescription: {
    display: 'flex',
    width: 450,
    height: '100px',
    border: 'none',
    alignItems: 'center',
    textAlign: 'left'
  },
  backButton: {
    margin: theme.spacing(3, 0, 2),
    width: 100
  },
  modalContentGrid: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    margin: 'auto',
    marginTop: '5%',
    width: '100%',
    maxWidth: '60vw',
    maxHeight: '80%',
    overflowY: 'auto',
  },
  backDrop: {
    background: 'rgba(255,255,255,0.2)',
  },
}))

const defaultProps = {
  m: 1,
  borderColor: 'text.primary'
}

const filterAdminPictures = images => {
    if (images === undefined) { return }
  return images.filter(image => image.description !== '[BEFORE]')
}

const filterUserPictures = images => {
    if (images === undefined) { return }
  return images.filter(image => image.description === '[BEFORE]')
}

const ReportModal = (props) => {
  const { report, apiKey, setIsModalOpen, comments, contactInfo, isModalOpen } = props;
  const commentList = generateShapedComments(comments)
  const pictureFromAdmin = filterAdminPictures(report.attachments)
  const pictureFromUser = filterUserPictures(report.attachments)

  const classes = useStyles()

  return (
      <Modal
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false)
          }}
      >
      <Grid
        container
        className={classes.modalContentGrid}
      >
      {/* 投稿画像 */}
      <Grid container item xs={12} style={{ justifyContent: 'center' }}>
        <Grid
          style={{
            display: 'flex',
            textAlign: 'center',
          }}
        >
          <Thumbnail pics={pictureFromUser} status="myReport" apiKey={apiKey} />
        </Grid>
      </Grid>
      {/* 投稿内容 */}
      <Grid container xs={12} style={{ justifyContent: 'center' }}>
        <Grid
          style={{
            display: 'flex',
            textAlign: 'center',
          }}
        >
          <Box display="flex" justifyContent="center">
            <Box borderTop={1} borderBottom={1} {...defaultProps}>
              <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell
                      align="center"
                      className={classes.tableCellinHead}
                    >
                      No
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinHead}
                    >
                      投稿者
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinHead}
                    >
                      ステータス
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinHead}
                    >
                      タイトル
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinHead}
                    >
                      投稿日付
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinHead}
                    >
                      レポートの種類
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinHeadDescription}
                    >
                      補足説明
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinHead}
                    >
                      現地住所
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  <TableRow key={report.title}>
                    <TableCell
                      align="center"
                      className={classes.tableCellinBody}
                    >
                      {report.id}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinBody}
                    >
                      {report.nickname}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinBody}
                    >
                      {report.status.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinBody}
                    >
                      {report.title}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinBody}
                    >
                      {report.createdOn}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinBody}
                    >
                      {report.tracker.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinBodyDescription}
                    >
                      {report.description !== null
                        ? report.description.length > 200
                          ? report.description.substring(0, 201) + '...'
                          : report.description
                        : null}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinBody}
                    >
                      {deriveValueFromCustomFields(
                        report.customFields,
                        '現地住所'
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* 担当者からのコメント */}
      <Title title={report.project.name.replace(/^\d*_/g, '')} />
      <Title title={contactInfo} />
      <Description description="担当者からのコメント" />
      <Grid container xs={12} style={{ justifyContent: 'center' }}>
        <Grid
          style={{
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center'
          }}
          direction="column"
        >
          {commentList.map((comment, idx) => {
            return <CommentsField key={idx} comment={comment} />
          })}
        </Grid>
      </Grid>
      <Grid container xs={12} style={{ justifyContent: 'center' }}>
        <Grid
          style={{
            display: 'flex',
            textAlign: 'center',
          }}
        >
          <Thumbnail pics={pictureFromAdmin} status="fromAdmin" apiKey={apiKey} />
        </Grid>
      </Grid>
            {/* 投稿地図 */}
            <Grid container xs={12} style={{ justifyContent: 'center' }}>
            <Grid
              style={{
                display: 'flex',
                textAlign: 'center',
              }}
            >
              <ReportMap issue={report} />
            </Grid>
          </Grid>
      </Grid>
    </Modal>
  )
}

export default ReportModal
