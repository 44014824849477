import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { withRouter } from 'react-router'

import ReportCard from './ReportCard'
import extractLatestPublicIssues from '../../domains/report/extractLatestPublicIssues'
import sortIssuesByUpdatedOn from '../../domains/report/sortIssuesByUpdatedOn'
import excludeTokyoRegion from '../../domains/report/excludeTokyoRegion'
import SubHeader from '../../modules/SubHeader'
import ToggleButton from '../../modules/ToggleButton'
import CircularIndicator from '../../modules/CircularIndicator'
import fetchAllIssues from '../../apis/report/fetchAllIssues'
import { generateShapedIssues } from '../../models/report'
import fetchMyIssues from '../../apis/report/fetchMyIssues'
import { getAuth } from '../../storage/auth'
import ProjectFilter from './ProjectFilter'
import ReportFilter from './ReportFilter'
import { getSessionCookie } from '../../domains/auth/session'
import ReportModal from './ReportModal'
import fetchComments from '../../apis/report/fetchComments'
import fetchProjectContactInfo from '../../apis/report/fetchProjectContactInfo'
import { dummyIssue } from '../../models/report/index'

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
}))

const ReportList = ({
  history,
  allIssues,
  selectAllReport,
  selectMyReport,
  selectReportType,
  selectProject,
  selectedTrackerIndex,
  selectedProjectIndex,
  apiKey,
  isLoading,
  isAllReport,
  trackerList,
  trackerId,
  offset,
  myOffset,
  limit,
  isProjectFilter,
  isTrackerFilter,
  projectList,
  projectId,
  setIsLoading
}) => {
  const [updatedAllIssues, setAllIssues] = useState(
    allIssues !== undefined ? allIssues : []
  )
  const [updatedOffset, setOffset] = useState(offset)
  const [updatedMyOffset, setMyOffset] = useState(myOffset)
  const [isFetching, setIsFetching] = useState(false)
  const [modalIssue, setModalIssue] = useState(dummyIssue)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [comments, setComments] = useState([])
  const [contactInfo, setContactInfo] = useState('')

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  function handleScroll() {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return
    if (isAllReport) {
      setOffset(updatedOffset + 25)
      fetchMoreListItems(updatedOffset + 25)
    } else {
      setMyOffset(updatedMyOffset + 25)
      fetchMoreMyItems(updatedMyOffset + 25)
    }
  }

  async function fetchMoreListItems(offset) {
    setIsFetching(true)
    const issues = await fetchAllIssues(
      apiKey,
      projectId,
      trackerId,
      offset,
      limit,
      isProjectFilter,
      isTrackerFilter
    )
    const excludedIssues = excludeTokyoRegion(issues.issues)
    const sortedIssues = sortIssuesByUpdatedOn(excludedIssues)
    const publicIssues = await extractLatestPublicIssues(sortedIssues)
    const shapedAllIssues = await generateShapedIssues(publicIssues) ?? []
    const concattedIssues = [...updatedAllIssues, ...shapedAllIssues]
    setAllIssues(concattedIssues)
    setIsFetching(false)
    setIsLoading(false)
  }

  async function fetchMoreMyItems() {
    setIsFetching(true)
    const authUser = getAuth()
    const myIssues = await fetchMyIssues(
      authUser.id,
      apiKey,
      updatedMyOffset,
      limit
    )
    const excludedIssues = excludeTokyoRegion(myIssues.issues)
    const sortedIssues = sortIssuesByUpdatedOn(excludedIssues)
    const shapedAllIssues = await generateShapedIssues(sortedIssues) ?? []
    const concattedIssues = [...updatedAllIssues, ...shapedAllIssues]
    setAllIssues(concattedIssues)
    setIsFetching(false)
  }

  const classes = useStyles()
  const navigateToMapPage = () => {
    history.push({
      pathname: '/maps',
    })
  }
  const navigateToPostReportPage = () => {
    const session = getSessionCookie()
    if (session.email !== undefined && session.password !== undefined) {
      history.push({
        pathname: '/postreport',
      })
    } else {
      history.push({
        pathname: '/',
      })
    }
  }

  const handleReportModal = async (issue) => {
    if (isLoading) { return }
    setModalIssue(issue)
    setIsModalOpen(true)
    const comments = await fetchComments(apiKey, issue.id)
    const contactInfo = await fetchProjectContactInfo(
      issue.project.id,
    )
    setComments(comments)
    setContactInfo(contactInfo)
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        <SubHeader
          title="レポート一覧"
          buttonTitleLeft="レポート一覧"
          buttonTitleMiddle="地図表示"
          buttonTitleRight="レポート投稿"
          toggleLeftButton={() => {}}
          toggleMiddleButton={() => navigateToMapPage()}
          toggleRightButton={() => navigateToPostReportPage()}
        />
        {isLoading && <CircularIndicator />}
        <Container className={classes.cardGrid} maxWidth="md">
          <ToggleButton
            selectAllReport={() => selectAllReport()}
            selectMyReport={() => selectMyReport()}
            leftTitle="全体レポート"
            rightTitle="マイレポート"
            isAllReport={isAllReport}
          />
          {isAllReport && (
            <ProjectFilter
              selectedIndex={selectedProjectIndex}
              selectProject={selectProject}
              projectList={projectList}
            />
          )}
          {isAllReport && (
            <ReportFilter
              selectedIndex={selectedTrackerIndex}
              selectReportType={selectReportType}
              trackerList={trackerList}
            />
          )}
          <Grid container spacing={3}>
            {updatedAllIssues.map((issue, i) => (
              <ReportCard
                report={issue}
                modalReport={modalIssue}
                isModalOpen={isModalOpen}
                apiKey={apiKey}
                key={i}
                handleReportModal={() => handleReportModal(issue)}
                setIsModalOpen={setIsModalOpen}
              />
            ))}
          </Grid>
          <ReportModal
            report={modalIssue}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            apiKey={apiKey}
            comments={comments}
            contactInfo={contactInfo}
          />
        </Container>
        {isFetching && <CircularIndicator />}
      </main>
    </React.Fragment>
  )
}

export default withRouter(ReportList)
