import Leaflet from 'leaflet'
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import 'leaflet/dist/leaflet.css'
import { Grid } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'

import Subheader from '../../modules/SubHeader'
import ReportMap from './ReportMap'
import ProjectFilter from '../report/ProjectFilter'
import isAvailable from '../../lib/isAvailable'
import { getSessionCookie } from '../../domains/auth/session'
import { getAuth } from '../../storage/auth'

Leaflet.Icon.Default.imagePath =
  '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/'

const Maps = ({
  history,
  issues,
  selectedProjectIndex,
  selectProject,
  projectList,
}) => {
  const [center, setCenter] = useState(null)
  const session = getSessionCookie()
  const navigateToPostReportPage = () => {
    if (isAvailable(session)) {
      history.push({
        pathname: '/postreport',
      })
    } else {
      history.push({
        pathname: '/',
      })
    }
  }

  const navigateToReportListPage = () => {
    history.push({
      pathname: '/reportlist',
    })
  }

  useEffect(() => {
    const authUser = getAuth() || {}
    switch (authUser.mainProject) {
      case '千葉市':
        setCenter(['35.607285', '140.106495'])
        break
      case '千代田区（テスト）':
        setCenter(['35.694044', '139.753447'])
        break
      case '九州電力':
        setCenter(['33.584261', '130.403789'])
        break
      case '加賀市':
        setCenter(['36.302668', '136.314688'])
        break
      case '和歌山県':
        setCenter(['34.230352', '135.170872'])
        break
      case '東広島市':
        setCenter(['34.426523', '132.743261'])
        break
      default:
        navigator.geolocation.getCurrentPosition(
          (pos) => setCenter([pos.coords.latitude, pos.coords.longitude]),
          (err) => setCenter(['35.607395', '140.106427'])
        )
        break
    }
  }, [])

  return (
    <div>
      <CssBaseline />
      <Subheader
        title="地図表示"
        buttonTitleLeft="レポート一覧"
        buttonTitleMiddle="地図表示"
        buttonTitleRight="レポート投稿"
        toggleLeftButton={() => navigateToReportListPage()}
        toggleMiddleButton={() => {}}
        toggleRightButton={() => navigateToPostReportPage()}
      />
      <ProjectFilter
        selectedIndex={selectedProjectIndex}
        selectProject={selectProject}
        projectList={projectList}
      />
      <Grid>
        <Grid
          col={12}
          style={{
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <ReportMap issues={issues} center={center} />
        </Grid>
      </Grid>
    </div>
  )
}

export default withRouter(Maps)
