import { baseUrl } from '../../constants'

export default async function fetchProject(projectId) {
  if (!projectId) {
    return {}
  }
  const traceParentProjectUrl = `${baseUrl}/projects/${projectId}.json`
  const traceResult = await fetch(traceParentProjectUrl)
  const traceResultJson = await traceResult.json()
  return traceResultJson
}
