import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { withRouter, Link as RouterLink } from 'react-router-dom'

import DropDownMenu from '../../modules/DropDownMenu'
import FadeMenu from '../../modules/FadeMenu'
import { GENDER_LISTS, OCCUPATION_LISTS, BIRTH_YEAR_LISTS } from '../../commons'

import signUpApi from '../../apis/auth/signUp'
import User from '../../models/auth/User'
import parseErrorExplanation from '../../domains/auth/parseErrorExplanation'
import extractProjectIdFromName from '../../domains/project/extractProjectIdFromName'
import traceParentProject from '../../domains/project/traceParentProject'
import signInApi from '../../apis/auth/signIn'
import fetchCitizenReporterRoleId from '../../apis/auth/fetchCitizenReporterRoleId'
import postUserToProjectMembership from '../../apis/project/postUserToProjectMembership'
import { setSessionCookie } from '../../domains/auth/session'
import { saveAuth } from '../../storage/auth'
import Description from '../../modules/Description'
import deriveIdFromProjectList from '../../domains/project/deriveIdFromProjectList'
import handleAllProjects from '../../apis/project/handleAllProjects'
import CircularIndicator from '../../modules/CircularIndicator'

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const SignUp = ({ history, projectLists }) => {
  const classes = useStyles()
  const [project, setProject] = useState('')
  const [projects, setProjectList] = useState(projectLists)
  const [birthYear, setBirthYear] = useState('')
  const [gender, setGender] = useState('')
  const [occupation, setOccupation] = useState('')
  const [nickName, setNickname] = useState('')
  const [firstName, setFirstname] = useState('')
  const [lastName, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [phoneNumber, setPhonenumber] = useState('')
  const [addressNumber, setAddressnumber] = useState('')
  const [address, setAddress] = useState('')
  const [isSupporter, toggleSupporter] = useState(false) //TODO: サポーター登録
  const [hasGroupSelected, toggleGroupSelection] = useState(false) //TODO: 団体登録
  const [errorMessage, setErrMsg] = useState('')
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(50)
  const [allProjects, setAllProjectList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const handleNickname = (event) => {
    setNickname(event.target.value)
  }
  const handleFirstname = (event) => {
    setFirstname(event.target.value)
  }
  const handleLastname = (event) => {
    setLastname(event.target.value)
  }
  const handleEmail = (event) => {
    setEmail(event.target.value)
  }
  const handlePassword = (event) => {
    setPassword(event.target.value)
  }
  const handlePasswordConfirmation = (event) => {
    setPasswordConfirmation(event.target.value)
  }
  const handlePhonenumber = (event) => {
    setPhonenumber(event.target.value)
  }
  const handleAddressnumber = (event) => {
    setAddressnumber(event.target.value)
  }
  const handleAddress = (event) => {
    setAddress(event.target.value)
  }

  const handleSignup = async (e) => {
    setIsLoading(true)
    e.preventDefault()
    const response = await signUpApi(
      email,
      password,
      firstName,
      lastName,
      nickName,
      isSupporter,
      phoneNumber,
      addressNumber,
      project.name,
      birthYear,
      gender,
      occupation,
      address,
      hasGroupSelected
    )
    if (!response.ok && response.type === 'opaqueredirect') {
      const signinResponse = await signInApi(email, password)
      const user = User(signinResponse)
      const apiKey = user.apiKey
      const projectId = deriveIdFromProjectList(projects, user.mainProject)
      const projectIdObject = { mainProjectId: projectId }
      const updatedUser = { ...user, ...projectIdObject }
      saveAuth(updatedUser)
      setSessionCookie({ email, password })
      const roleId = await fetchCitizenReporterRoleId()
      const projectList = await handleAllProjects(allProjects, offset, limit)
      const selectedProjectId = await extractProjectIdFromName(
        project.name,
        projectList
      )
      const parentProjectId = await traceParentProject(selectedProjectId)
      postUserToProjectMembership(roleId, apiKey, parentProjectId)
      setIsLoading(false)
      history.push({
        pathname: '/reportlist',
      })
    } else {
      const responseText = await response.text()
      const errorExplanationElem = await parseErrorExplanation(responseText)
      setErrMsg(errorExplanationElem.innerText)
      setIsLoading(false)
    }
  }

  const handleProject = (e) => {
    const project = e.target.value
    setProject(project)
  }

  const isValid = () => {
    if (
      email &&
      nickName &&
      firstName &&
      lastName &&
      phoneNumber &&
      password === passwordConfirmation &&
      project
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {isLoading && <CircularIndicator />}
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          My City Reportへようこそ
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="nickName"
                label="ニックネーム(レポート公開時に公開)"
                name="nickname"
                onChange={handleNickname}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="lastName"
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="姓"
                autoFocus
                onChange={handleLastname}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="名"
                name="firstName"
                autoComplete="lname"
                onChange={handleFirstname}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="メール(重複不可)"
                name="email"
                autoComplete="email"
                onChange={handleEmail}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="パスワード(10桁以上)"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handlePassword}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="passwordConfirmation"
                label="パスワードの確認"
                type="password"
                id="passwordConfirmation"
                autoComplete="current-password"
                onChange={handlePasswordConfirmation}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="phone"
                label="電話番号(例:09012345678)"
                name="phone"
                onChange={handlePhonenumber}
              />
            </Grid>
            <FadeMenu
              title="主な投稿先を選択して下さい"
              listItems={projectLists}
              selectedValue={project}
              setValue={setProject}
              handleChange={handleProject}
            />
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="addressNumber"
                label="郵便番号(例:1101234)"
                name="addressNumber"
                onChange={handleAddressnumber}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="address"
                label="住所"
                name="address"
                onChange={handleAddress}
              />
            </Grid>
          </Grid>
          <Box display="flex" flexDirection="row" justifyContent="center">
            <DropDownMenu
              id="birthYear"
              title="誕生年を選択"
              listItems={BIRTH_YEAR_LISTS}
              selectedValue={birthYear}
              setValue={setBirthYear}
            />
            <DropDownMenu
              id="gender"
              title="性別を選択"
              listItems={GENDER_LISTS}
              selectedValue={gender}
              setValue={setGender}
            />
            <DropDownMenu
              id="occupation"
              title="職業を選択"
              listItems={OCCUPATION_LISTS}
              selectedValue={occupation}
              setValue={setOccupation}
            />
          </Box>
          <div style={{ color: 'red' }}>{errorMessage}</div>
          <Description description="*は入力必須" />
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            color={isValid() ? 'primary' : 'secondary'}
            className={classes.submit}
            onClick={(e) =>
              isValid()
                ? handleSignup(e)
                : setErrMsg('登録内容が満たされていません')
            }
          >
            新規登録
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link component={RouterLink} to="">
                すでに登録済ですか? ログイン
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  )
}

export default withRouter(SignUp)
