import moment from 'moment'

const Comment = obj => ({
  id: obj.id,
  createdOn:
    obj.created_on === null
      ? null
      : moment(obj.created_on).format('YYYY-MM-DD'),
  description: obj.notes
})

export default Comment
