import { NEW, IN_PROGRESS, END } from '../../commons'

export default function changeBgColorByStatus(statusName) {
  if (NEW.includes(statusName)) {
    return '#BA3E46'
  } else if (IN_PROGRESS.includes(statusName)) {
    return '#E8B03C'
  } else if (END.includes(statusName)) {
    return '#458934'
  }
}
