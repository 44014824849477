export default async function extractAuthToken(responseText) {
  const domparser = new DOMParser()
  const doc = domparser.parseFromString(responseText, 'text/html')
  const authenticityTokenElems = await doc.getElementsByName(
    'authenticity_token'
  )
  let authenticityToken
  if (authenticityTokenElems.length === 1) {
    authenticityToken = authenticityTokenElems[0].getAttribute('value')
  }
  return authenticityToken
}
