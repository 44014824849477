import React, { useState, useEffect, useRef } from 'react'
import L from 'leaflet'
import LCG from 'leaflet-control-geocoder'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import { Grid, Button } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { withRouter } from 'react-router'
import { TextField } from 'material-ui'

import { baseUrl } from '../../constants'
import { getAuth } from '../../storage/auth'
import postReportApi from '../../apis/report/postReport'
import generateReportImage from '../../models/report/generateReportImages'
import FormButton from '../../modules/FormButton'
import TrackerList from '../../modules/TrackerList'
import fetchPostableProjectList from '../../apis/project/fetchPostableProjectList'
import extractReportReceptableProject from '../../domains/report/extractReportReceptableProject'
import deriveCurrentAddressFromCoords from '../../apis/report/deriveCurrentAddressFromCoords'
import ReportImage from './ReportImage'
import { makeStyles } from '@material-ui/core/styles'
import ReportTitle from '../../modules/ReportTitle'
import Description from '../../modules/Description'
import ReportTextFields from '../../modules/ReportTextFields'
import fetchCitizenReporterRoleId from '../../apis/auth/fetchCitizenReporterRoleId'
import traceParentProject from '../../domains/project/traceParentProject'
import postUserToProjectMembership from '../../apis/project/postUserToProjectMembership'
import AlertModal from '../../modules/AlertModal'
import deriveValueFromCustomField from '../../lib/deriveValueFromCustomFields'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 20,
    marginTop: 20,
  },
}))

const createObjectURL =
  (window.URL || window.webkitURL).createObjectURL || window.createObjectURL

const PostReport = ({ history }) => {
  const classes = useStyles()
  const [hasLocation, setHasLocation] = useState(false)
  const [latlng, setLatLng] = useState({
    lat: '',
    lng: '',
  })
  const [pinAddress, setPinAddress] = useState('')
  const [pinLat, setPinLat] = useState('')
  const [pinLng, setPinLng] = useState('')
  const [imageSrc, setImageSrc] = useState(['', '', '', ''])
  const [imageFiles, setImageFile] = useState([])
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [tokens, setToken] = useState([])
  const [currentAddress, setCurrentAddress] = useState('')
  const [tracker, setTracker] = useState('')
  const [projectList, setProjectList] = useState([])
  const [projectName, setProjectName] = useState('')
  const [trackerList, setTrackerList] = useState([])
  const [projectId, setProjectId] = useState('')
  const [currentUser, setCurrentUser] = useState('')
  const [isUploaded, setIsUploaded] = useState([false, false, false, false])
  const [privacyInfo, setPrivacyInfo] = useState(false)
  const [zoom, setZoom] = useState(13)
  const [roleId, setRoleId] = useState(null)
  const [parentProjectId, setParentProjectId] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isInitialUser, setIsInitialUser] = useState(false)
  const [isTokyoPrefacture, setIsTokyoPrefacture] = useState(false)
  const mapRef = useRef(null)
  const authUser = getAuth() || {}
  const apiKey = authUser.apiKey
  const minZoom = 3
  const maxZoom = 19.8

  useEffect(() => {
    const authUser = getAuth() || {}
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    }
    const getCurrentPosition = async () => {
      return new Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, reject, options)
      })
    }
    getCurrentPosition().then((position) => {
      const coords = position.coords
      if (authUser.mainProject === '千葉市') {
        setLatLng({
          lat: '35.607395',
          lng: '140.106427',
        })
      } else {
        setLatLng({
          lat: coords.latitude,
          lng: coords.longitude,
        })
      }
    })
    const currentUser = getAuth()
    setCurrentUser(currentUser)
  }, [])

  const displayPinIcon = async (latlng) => {
    const map = mapRef.current.leafletElement
    const geocoder = L.Control.Geocoder.nominatim()
    geocoder.reverse(
      latlng,
      map.options.crs.scale(map.getZoom()),
      (results) => {
        const r = results[0]
        setPinAddress(r.name)
        setPinLat(latlng.lat)
        setPinLng(latlng.lng)
        handleProjectList(apiKey, latlng)
      }
    )
    const currentAddress = await deriveCurrentAddressFromCoords(latlng)
    setCurrentAddress(currentAddress)
  }

  const handleProjectList = async (apiKey, latlng) => {
    const projectList = await fetchPostableProjectList(apiKey, latlng)
    const projectName = projectList.shift().name.replace(/^\d*_/g, '')
    setProjectName(projectName)
    if (projectName.includes('千代田区') ||
        projectName.includes('港区') ||
        projectName.includes('品川区') ||
        projectName.includes('目黒区') ||
        projectName.includes('大田区') ||
        projectName.includes('練馬区') ||
        projectName.includes('葛飾区') ||
        projectName.includes('八王子市') ||
        projectName.includes('国分寺市') ||
        projectName.includes('国立市') ||
        projectName.includes('福生市') ||
        projectName.includes('武蔵村山市') ||
        projectName.includes('多摩市') ||
        projectName.includes('稲城市') ||
        projectName.includes('東京') ||
        projectName.includes('神奈川') ||
        projectName.includes('お試し') ||
        projectName.includes('運用未実施')
    ) {
      setIsTokyoPrefacture(true)
    }
    const projects = extractReportReceptableProject(projectList)
    setProjectList(projects)
    handleTrackerList(projects)
  }

  const handleTrackerList = (projectList) => {
    if (projectList.length === 0) return
    const trackerList = projectList.map((project) =>
      project.trackers
        .filter((tracker) => !tracker.name.includes('協働案件'))
        .filter((tracker) => !tracker.name.includes('完了レポート'))
        .filter((tracker) => tracker.name !== '下水道')
        .filter((tracker) => tracker.name !== '自転車')
    )
    setTrackerList(trackerList)
  }

  const handleInitialPosition = () => {
    if (authUser.mainProject === '千葉市') {
      setLatLng({
        lat: '35.607395',
        lng: '140.106427',
      })
    } else {
      mapRef.current.leafletElement.locate()
    }
  }

  //ピンの配置
  const setPin = (e) => {
    const zoom = mapRef.current.leafletElement.getZoom()
    setZoom(zoom)
    handleLocationFound(e)
  }

  //位置が見つかった時
  const handleLocationFound = (e) => {
    setHasLocation(true)
    setLatLng(e.latlng)
    displayPinIcon(e.latlng)
  }

  /*--------------------------------フォーム関係の関数--------------------------------*/

  //WIP:フォームが変わった時
  const handleChange = (e) => {
    switch (e.target.name) {
      case 'title':
        setTitle(e.target.value)
        break
      case 'description':
        setDescription(e.target.value)
        break
      default:
        console.log('not title or description')
    }
  }

  const handleImageChange = (e) => {
    const num = e.currentTarget.getAttribute('data-num')
    const file = e.target.files[0]
    const newImageFiles = [...imageFiles]
    newImageFiles[num] = file
    setImageFile(newImageFiles)
    uploadImage(file)
    const imageUrl = createObjectURL(file)
    const newImageSrc = [...imageSrc]
    newImageSrc[num] = imageUrl
    setImageSrc(newImageSrc)
    const newIsUploaded = [...isUploaded]
    newIsUploaded[num] = true
    setIsUploaded(newIsUploaded)
    e.target.value = null
  }

  const onClickDeletePhoto = (num) => {
    const newImageSrc = [...imageSrc]
    newImageSrc[num] = ''
    setImageSrc(newImageSrc)
    const newIsUploaded = [...isUploaded]
    newIsUploaded[num] = false
    setIsUploaded(newIsUploaded)
    const newImageFiles = [...imageFiles]
    newImageFiles.splice(num, 1)
    setImageFile(newImageFiles)
    tokens.splice(num, 1)
    setToken(tokens)
  }

  const uploadImage = async (file) => {
    const requestUrl = baseUrl + '/uploads.json'
    try {
      const uploadResponse = await fetch(requestUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/octet-stream',
          'X-Redmine-API-Key': apiKey,
        },
        body: file,
      })
      const uploadResponseJson = await uploadResponse.json()
      const token = await uploadResponseJson.upload.token
      setToken([...tokens, token])
    } catch (e) {
      console.log(e)
    }
  }

  //TODO: リファクタリング
  async function submitHandler(e) {
    e.preventDefault()
    const trackerName = tracker.name
    const images = generateReportImage(imageFiles, tokens, trackerName)
    const coords = {
      longitude: pinLng,
      latitude: pinLat,
    }
    const selectedProjectId = projectId
    const selectedTrackerId = tracker.id
    const checkedThemeId = null
    const mail = currentUser.mail
    const phoneNumber = currentUser.phoneNumber
    const nickName = currentUser.displayName
    const address = pinAddress
    const response = await postReportApi(
      images,
      coords,
      selectedProjectId,
      selectedTrackerId,
      title,
      description,
      apiKey,
      checkedThemeId,
      trackerName,
      mail,
      phoneNumber,
      address,
      currentAddress,
      nickName
    )
    if (response.status == 201) {
      const body = await response.json()
      const issue = body.issue
      const reportId = issue.id
      const displayName = issue.author.name
      const statusName = issue.status.name
      const createdOn = body.issue.created_on.substring(0, 10)
      history.push({
        pathname: '/postresult',
        state: {
          reportId: reportId,
          displayName: displayName,
          status: statusName,
          title: title,
          createdOn: createdOn,
          trackerName: trackerName,
          description: description,
          currentAddress: currentAddress,
          imageSrc0: imageSrc[0],
          imageSrc1: imageSrc[1],
          imageSrc2: imageSrc[2],
          imageSrc3: imageSrc[3],
        },
      })
    }
  }

  const handleTracker = (e) => {
    setTracker(e.target.value)
    handleProjectId(projectList, e.target.value)
  }

  const checkCurrentUserAndAddUserIntoProject = async (apiKey, projectId) => {
    const userRequestUrl = `${baseUrl}/my/account.json`
    const userResponse = await fetch(userRequestUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Redmine-API-Key': apiKey,
      },
    })
    const userResult = await userResponse.json()
    const user = userResult.user
    const admissionsRequestUrl = `${baseUrl}/projects/${projectId}/admissions.json`
    const admissionsResponse = await fetch(admissionsRequestUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Redmine-API-Key': apiKey,
      },
    })
    const isInitialUser = deriveValueFromCustomField(
      user.custom_fields,
      '初期投稿チェック'
    )
    setIsInitialUser(isInitialUser)
    if (admissionsResponse.ok) {
      setPrivacyInfo(true)
    }
    handleUserPrivacyInfo(projectId)
  }

  const handleUserPrivacyInfo = async (projectId) => {
    const roleId = await fetchCitizenReporterRoleId()
    setRoleId(roleId)
    const parentProjectId = await traceParentProject(projectId)
    setParentProjectId(parentProjectId)
    setIsOpen(true)
  }

  const handleProjectId = (projectList, tracker) => {
    projectList.map((project) => {
      project.trackers.map((projectTracker) => {
        if (projectTracker === tracker) {
          setProjectId(project.id)
          checkCurrentUserAndAddUserIntoProject(apiKey, project.id)
        }
      })
    })
  }

  const imageButton = (value, i) => {
    return isUploaded[value] === false ? (
      <Grid
        col={12}
        style={{
          textAlign: 'center',
        }}
      >
        <Button variant="outlined" component="label">
          {tracker.name === 'かいけつレポート'
            ? i === 0
              ? '事前'
              : '事後'
            : '写真' + `${i + 1}`}
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            data-num={value}
            style={{ display: 'none' }}
          />
        </Button>
      </Grid>
    ) : null
  }

  return (
    <div>
      <CssBaseline />
      {!privacyInfo || !isInitialUser ? (
        <AlertModal
          roleId={roleId}
          parentProjectId={parentProjectId}
          apiKey={apiKey}
          postUserToProjectMembership={postUserToProjectMembership}
          isOpen={isOpen}
        />
      ) : null}
      <ReportTitle title="(1)写真を添付 " />
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={2}>
            {[0, 1, 2, 3].map((value, i) => (
              <Grid key={value} item>
                <Grid col={3}>
                  {imageButton(value, i)}
                  <Grid
                    col={12}
                    style={{
                      marginTop: 10,
                      textAlign: 'center',
                    }}
                  >
                    <ReportImage
                      imageSrc={imageSrc[value]}
                      onClickDelete={() => {
                        onClickDeletePhoto(value)
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Description
        description={
          '「こまったレポート」の写真は極力、近景と遠景の両方を撮ってください。\n「かいけつレポート」の写真は1件目に処理前、2件目以降に処理後の写真を添付してください。'
        }
      />

      <ReportTitle title="(2)地図をクリックして位置を指定" />
      <Grid>
        <Grid
          col={12}
          style={{
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Map
            style={{
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'center',
              height: 600,
              width: 600,
            }}
            center={latlng}
            length={4}
            onClick={(e) => {
              setPin(e)
            }}
            onLocationfound={handleLocationFound}
            ref={mapRef}
            zoom={zoom}
            minZoom={minZoom}
            maxZoom={maxZoom}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {hasLocation ? (
              <Marker position={latlng}>
                <Popup>
                  <span>You are here</span>
                </Popup>
              </Marker>
            ) : null}
          </Map>
        </Grid>
      </Grid>

      {/*  現在地の取得 */}
      <Grid
        style={{
          textAlign: 'center',
        }}
      >
        <FormButton
          description="ピンを最初の位置に戻す"
          handleOnClick={() => handleInitialPosition()}
        />
      </Grid>
      {hasLocation && projectList.length !== 0 ? (
        <Grid
          col={12}
          style={{
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <ReportTextFields title="投稿先" description={projectName} />
        </Grid>
      ) : null}
      <Grid
        style={{
          textAlign: 'center',
        }}
        container
        direction="column"
      >
        <ReportTitle title="(3)投稿先のカテゴリを選択" />
        <TrackerList
          title={'投稿内容のカテゴリを選択'}
          emptyMessage={'地図をクリックして位置を確定してください'}
          projectLists={projectList}
          selectedValue={tracker}
          setValue={setTracker}
          handleChange={handleTracker}
        />
      </Grid>

      <form onSubmit={submitHandler}>
        <Grid
          container
          spacing={2}
          style={{
            textAlign: 'center',
          }}
        >
          <Grid item xs={12}>
            <ReportTitle title="(4)タイトルと補足説明を入力" />
            <TextField
              variant="outlined"
              label="タイトル"
              placeholder="タイトル"
              required
              name="title"
              onChange={handleChange}
              margin="normal"
              style={{ width: 400 }}
            />
          </Grid>
          <Grid item container alignItems="center" justify="center">
            <Grid item>
              <TextField
                variant="outlined"
                label="補足説明"
                placeholder="補足説明"
                name="description"
                onChange={handleChange}
                margin="normal"
                multiLine
                rows={6}
                style={{ width: 400 }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {pinAddress !== '' &&
            imageSrc[`${tracker.name !== 'かいけつレポート' ? 0 : 1}`] !== '' &&
            title !== '' &&
            !isTokyoPrefacture ? (
              <Button variant="outlined" color="primary" type="submit">
                レポートを送信
              </Button>
            ) : isTokyoPrefacture ? (
              <Button variant="outlined" color="secondary">
                東京都はまだ投稿出来ません
              </Button>
            ) : (
              <Button variant="outlined" color="secondary">
                未入力項目があります
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default withRouter(PostReport)
