import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { AccountCircle, Home, Note, PermIdentity, Phone, LocalLibrary } from '@material-ui/icons'

import { getSessionCookie } from '../domains/auth/session'
import { DRAWER_LIST } from '../commons'

const useStyles = makeStyles({
  title: {
    flexGrow: 1,
  },
  header: {
    backgroundColor: '#5e6df2',
  },
})

const Header = ({ history, removeSessionAndAuth }) => {
  const classes = useStyles()
  const session = getSessionCookie()
  const [state, setState] = useState({ right: false })

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [side]: open })
  }

  const navigateToProfile = () => {
    setState({ state: false })
    history.push({
      pathname: '/profile',
    })
  }

  //TODO: 緊急連絡先
  const navigateToEmergContact = () => {
    setState({ state: false })
    history.push({
      pathname: '/emergcontact',
    })
  }

  const handleDrawer = (side, index) => {
    toggleDrawer(side, false)
    switch (index) {
      case 0:
        navigateToProfile()
        break
      case 1:
        navigateToEmergContact()
        break
      case 2:
        window.open('https://www.mycityreport.jp/about', '_self')
        break
      case 3:
        window.open('https://www.mycityreport.jp/terms', '_self')
        break
      case 4:
        window.open('https://www.mycityreport.jp/guideline', '_self')
        break
    }
  }

  const DrawerList = (side) => (
    <div className={classes.list} role="presentation">
      <List>
        {DRAWER_LIST.map((text, index) => (
          <ListItem button key={text} onClick={() => handleDrawer(side, index)}>
            <ListItemIcon>
              {(() => {
                if (index === 0) {
                  return <PermIdentity />
                }
                if (index === 1) {
                  return <Phone />
                }
                if (index === 2) {
                  return <Home />
                }
                if (index === 3) {
                  return <Note />
                }
                if (index === 4) {
                  return <LocalLibrary />
                }
              })()}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  )

  return (
    <React.Fragment>
      <AppBar position="static" className={classes.header}>
        <Toolbar>
          <Typography variant="h6" className={classes.title} color="inherit">
            My City Report
          </Typography>
          {session.email !== undefined && session.password !== undefined && (
            <div>
              <Button
                onClick={() => removeSessionAndAuth(history)}
                color="inherit"
              >
                Logout
              </Button>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer('right', true)}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Drawer
                anchor="right"
                open={state.right}
                onClose={toggleDrawer('right', false)}
              >
                {DrawerList('right')}
              </Drawer>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
}

export default withRouter(Header)
