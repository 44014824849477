import deriveValueFromCustomFields from '../../lib/deriveValueFromCustomFields'

export default function extractMainProjects(projects) {
  const listOfMainProjects = []

  projects.map((project) => {
    const emergContact = deriveValueFromCustomFields(
      project.custom_fields,
      '緊急連絡先情報'
    )
    const projectObject = {}
    projectObject['id'] = project.id
    projectObject['title'] = project.name
    projectObject['name'] = project.name.replace(/^\d*_/g, '')
    projectObject['emergContact'] = emergContact
    listOfMainProjects.push(projectObject)
  })

  return listOfMainProjects
}
