export default function extractAvblTrackers(trackers) {
  const avblTrackers = trackers.filter(
    (tracker) =>
      !tracker.name.includes('完了レポート') &&
      !tracker.name.includes('協働案件') &&
      tracker.name !== '下水道' &&
      tracker.name !== '自転車'
  )
  return avblTrackers
}
