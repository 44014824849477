import { baseUrl } from '../../constants'
import { Base64 } from '../../commons'

export default function signInApi(mail, password) {
  const requestUrl = baseUrl + '/my/account.json'
  const options = {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + Base64.btoa(`${mail}:${password}`)
    }
  }
  return fetch(requestUrl, options)
    .then(response => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response.json()
    })
    .then(responseAsJson => {
      return responseAsJson
    })
    .catch(error => {
      console.log('Looks like there was a problem: \n', error)
    })
}
