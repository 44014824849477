import generateEndpointUrl from '../../domains/report/generateEndpointUrl'

export default async function fetchAllIssues(
  apiKey,
  projectId,
  trackerId,
  offset,
  limit,
  isProjectFilter,
  isTrackerFilter
) {
  const requestConfig = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Redmine-API-Key': apiKey,
    },
  }
  const url = generateEndpointUrl(
    projectId,
    trackerId,
    offset,
    limit,
    isProjectFilter,
    isTrackerFilter
  )
  const issues = await fetch(url, requestConfig)
  const issuesJson = await issues.json()
  return issuesJson
}
