import React, { Fragment } from 'react'
import ToggleButton from '@material-ui/lab/ToggleButton'

const SmallButton = (props) => {
  const { index, title, trackerId, selected, selectReportType } = props
  return (
    <Fragment>
      <ToggleButton
        variant={'outlined'}
        selected={selected}
        size="small"
        color="primary"
        onClick={() => selectReportType(trackerId, index)}
        value="group"
      >
        {title}
      </ToggleButton>
    </Fragment>
  )
}

export default SmallButton
