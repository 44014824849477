import AvatarDouro from '../../assets/images/white/douro_white.png'
import AvatarGomi from '../../assets/images/white/gomi_white.png'
import AvatarKouen from '../../assets/images/white/kouen_white.png'
import AvatarKaiketsu from '../../assets/images/white/kaiketsu_white.png'
import AvatarOther from '../../assets/images/white/other_report_white.png'

export default function changeIconByTracker(trackerName) {
  if (
    trackerName === '道路' ||
    trackerName === '下水道' ||
    trackerName === '自転車'
  ) {
    return AvatarDouro
  } else if (trackerName === 'ごみ') {
    return AvatarGomi
  } else if (trackerName === '公園') {
    return AvatarKouen
  } else if (trackerName === 'かいけつレポート') {
    return AvatarKaiketsu
  } else {
    return AvatarOther
  }
}
