import React from 'react'
import { Grid, Button } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import changeBgColorByStatus from '../../domains/report/changeBgColorByStatus'
import ImageAvatar from '../../modules/ImageAvatar'

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  textBox: {
    marginRight: '5px',
  },
  statusBox: {
    height: 23,
    marginLeft: 1.5,
  },
}))

const handleReportImage = (report, apiKey) => {
  if (report.attachments.length !== 0) {
    return report.attachments[0].thumbnail_url + '?key=' + apiKey
  } else {
    return undefined
  }
}

const ReportCard = (props) => {
  const classes = useStyles()

  const { report, apiKey, handleReportModal } = props
  const title = report.title
  const statusName = report.status.name
  const nickName = report.nickname
  const createdOn = report.createdOn
  const projectName = report.project.name
  const trackerName = report.tracker.name

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className={classes.card}>
        <CardMedia
          key={report.id}
          className={classes.cardMedia}
          image={handleReportImage(report, apiKey)}
          onClick={handleReportModal}
        />
        <CardContent className={classes.cardContent}>
          <Box display="flex" justifyContent="flex-start">
            <Box fontWeight="fontWeightBold" fontSize={15} width="100%">
              {title.length > 9 ? title.substring(0, 10) + '...' : title}
            </Box>
            <ImageAvatar
              trackerName={trackerName}
              statusColor={changeBgColorByStatus(statusName)}
            />
            <Box
              className={classes.statusBox}
              flexShrink={0}
              bgcolor={changeBgColorByStatus(statusName)}
              color="primary.contrastText"
              padding={0.33}
              fontWeight="fontWeightBold"
              borderRadius={5}
            >
              {statusName}
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-start">
            <Box className={classes.textBox} fontWeight="fontWeightRegular">
              {nickName}
            </Box>
            <Box className={classes.textBox} fontWeight="fontWeightLight">
              {createdOn}
            </Box>
          </Box>
        </CardContent>
        <CardContent>
          <Typography variant="subtitle2" gutterBottom>
            {projectName.replace(/^\d*_/g, '')}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" onClick={handleReportModal}>
            詳細を見る
          </Button>
        </CardActions>
      </Card>
    </Grid>
  )
}

export default ReportCard
