import { baseUrl } from '../../constants'

export default async function getRegisterResponse() {
  const requestUrl = baseUrl + '/account/register'
  const getResponse = await fetch(requestUrl, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'text/html'
    },
    credentials: 'include'
  })
  const getResponseText = await getResponse.text()
  return getResponseText
}
