import { baseUrl } from '../../constants'

export default async function fetchPostableProjectList(apiKey, latlng) {
  const latitude = latlng.lat
  const longitude = latlng.lng
  const requestUrl =
    baseUrl +
    '/projects.json?contains=POINT(' +
    `${longitude}` +
    '%20' +
    `${latitude}` +
    ')&include=trackers&limit=100'
  const lists = await fetch(requestUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Redmine-API-Key': apiKey,
    },
  })
  const projectListsJson = await lists.json()
  const projectList = projectListsJson.projects
  return projectList
}
