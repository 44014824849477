import React from 'react'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

const CopyRight = () => {
  return (
    <Typography variant="body2" color="inherit" align="center">
      {'Copyright © 2019-2022 '}
      <Link color="inherit" href="https://www.mycityreport.jp/">
        My City Report 運営事務局
      </Link>
    </Typography>
  )
}

export default CopyRight
