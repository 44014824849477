import React from 'react'
import { Map, TileLayer, Marker } from 'react-leaflet'
import { withRouter } from 'react-router'
import Leaflet from 'leaflet'
import 'leaflet/dist/leaflet.css'

import displayMapIcon from '../../domains/map/displayMapIcon'

Leaflet.Icon.Default.imagePath =
  '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/'

const ReportMap = ({ history, issues, center }) => {
  const zoom = 12
  const minZoom = 3
  const maxZoom = 19.8
  const navigateToDetailPage = (issue) => {
    issue = issue.issue
    history.push({
      pathname: '/reportdetail',
      state: {
        report: issue,
      },
    })
  }

  return (
    <div>
      <Map
        center={center}
        zoom={zoom}
        minZoom={minZoom}
        maxZoom={maxZoom}
        style={{ height: '500px', width: '500px' }}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {issues !== undefined
          ? issues
              .filter((issue) => issue.geojson !== '')
              .map((issue) => (
                <Marker
                  position={[
                    JSON.parse(issue.geojson).geometry.coordinates[1],
                    JSON.parse(issue.geojson).geometry.coordinates[0],
                  ]}
                  title={issue.subject}
                  key={'marker_' + issue.id}
                  onClick={() => {
                    navigateToDetailPage({ issue })
                  }}
                  icon={displayMapIcon(issue)}
                />
              ))
          : null}
      </Map>
    </div>
  )
}
export default withRouter(ReportMap)
