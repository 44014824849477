export const chars =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='

export const Base64 = {
  btoa: (input: string = '') => {
    let str = input
    let output = ''

    for (
      let block = 0, charCode, i = 0, map = chars;
      str.charAt(i | 0) || ((map = '='), i % 1);
      output += map.charAt(63 & (block >> (8 - (i % 1) * 8)))
    ) {
      charCode = str.charCodeAt((i += 3 / 4))

      if (charCode > 0xff) {
        throw new Error(
          "'btoa' failed: The string to be encoded contains characters outside of the Latin1 range."
        )
      }

      block = (block << 8) | charCode
    }

    return output
  }
}

export const GENDER_LISTS = ['答えたくない', '男', '女']

export const BIRTH_YEAR_LISTS = [
  '1920',
  '1921',
  '1922',
  '1923',
  '1924',
  '1925',
  '1926',
  '1927',
  '1928',
  '1929',
  '1930',
  '1931',
  '1932',
  '1933',
  '1934',
  '1935',
  '1936',
  '1937',
  '1938',
  '1939',
  '1940',
  '1941',
  '1942',
  '1943',
  '1944',
  '1945',
  '1946',
  '1947',
  '1948',
  '1949',
  '1950',
  '1951',
  '1952',
  '1953',
  '1954',
  '1955',
  '1956',
  '1957',
  '1958',
  '1959',
  '1960',
  '1961',
  '1962',
  '1963',
  '1964',
  '1965',
  '1966',
  '1967',
  '1968',
  '1969',
  '1970',
  '1971',
  '1972',
  '1973',
  '1974',
  '1975',
  '1976',
  '1977',
  '1978',
  '1979',
  '1980',
  '1981',
  '1982',
  '1983',
  '1984',
  '1985',
  '1986',
  '1987',
  '1988',
  '1989',
  '1990',
  '1991',
  '1992',
  '1993',
  '1994',
  '1995',
  '1996',
  '1997',
  '1998',
  '1999',
  '2000',
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2011',
  '2012',
  '2013',
  '2014',
  '2015',
  '2016',
  '2017',
  '2018',
  '2019'
]

export const OCCUPATION_LISTS = [
  '農・林・漁業',
  '自営業・家族従事者',
  '会社員(役員含む)',
  '公務員・団体職員',
  '自由業',
  '学生',
  '専業主婦・主夫',
  'パート・アルバイト',
  '無職',
  'その他'
]

export const PROJECT_LIST = [
  '千葉市',
  '室蘭市',
  '墨田区',
  '市原市',
  '沼津市',
  '茂原市',
  '足立区',
  '東広島市',
  '六甲アイランド[テスト]',
  '千代田区[テスト]',
  'お試し投稿'
]

export const STATUS_ARRAY = ['未完了', '完了', 'すべて']

export const DRAWER_LIST = [
  'プロフィール',
  '電話連絡先一覧',
  'My City Reportとは',
  '利用規約',
  '公開ガイドライン'
]

export const NEW = ['新規', '受付済']

export const IN_PROGRESS = [
  '調査中',
  '募集中',
  '対応予定',
  '経過観察',
  '協働案件化',
  '市外取次',
  '対応中'
]

export const END = ['終了', '却下', '外部機関へ取次', '対応済']

export const INPROGRESS_STATUS = ['調査予定', '検討中', '対応予定']

export const FINISHED_STATUS = [
  '対応済（現場対応）完了',
  '対応済（現場対応）経過観察',
  '対応済（説明のみ）完了',
  '対応済（説明のみ）経過観察',
  'イタズラ',
  '現地不明',
  '却下'
]

export const ESCALATION_STATUS = ['外部機関へ取次ぎ']
