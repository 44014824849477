import convertStatusByName from '../report/convertStatusByName'
import generateImageStatusFromStatusName from '../map/generateImageStatusFromStatusName'

const returnIconByStatus = (issue) => {
  const status = issue.status
  const convertedStatusName = convertStatusByName(status.name)
  const trackerName = issue.tracker.name
  const imageStatus = generateImageStatusFromStatusName(convertedStatusName)

  if (
    trackerName === '道路' ||
    trackerName === '下水道' ||
    trackerName === '自転車' ||
    trackerName === '困ったレポート'
  ) {
    return require(`../../assets/images/${imageStatus}/douro.png`)
  } else if (trackerName === 'ごみ') {
    return require(`../../assets/images/${imageStatus}/gomi.png`)
  } else if (trackerName === '公園') {
    return require(`../../assets/images/${imageStatus}/kouen.png`)
  } else if (trackerName === 'テーマレポート') {
    return require(`../../assets/images/${imageStatus}/theme-report.png`)
  } else if (
    trackerName === 'かいけつレポート' ||
    trackerName === 'お試しレポート'
  ) {
    return require(`../../assets/images/${imageStatus}/kaiketsu.png`)
  } else if (
    trackerName === '参加申し込み' ||
    trackerName === '完了レポート' ||
    trackerName === 'サポーター活動'
  ) {
    return require(`../../assets/images/${imageStatus}/kyoudou.png`)
  } else if (trackerName === '水路・河川') {
    return require(`../../assets/images/${imageStatus}/river.png`)
  } else if (trackerName === 'その他') {
    return require(`../../assets/images/${imageStatus}/others.png`)
  } else {
    return require(`../../assets/images/${imageStatus}/others.png`)
  }
}

export default returnIconByStatus
