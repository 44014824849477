import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Box
} from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { withRouter } from 'react-router-dom'

import generateShapedComments from '../../models/report/generateShapedComments'
import Thumbnail from '../map/Thumbnail'
import CommentsField from '../../modules/CommentsField'
import ReportMap from '../../modules/ReportMap'
import Title from '../../modules/Title'
import Description from '../../modules/Description'
import deriveValueFromCustomFields from '../../lib/deriveValueFromCustomFields'
import fetchComments from '../../apis/report/fetchComments'
import fetchProjectContactInfo from '../../apis/report/fetchProjectContactInfo'

const useStyles = makeStyles(theme => ({
  table: {
    width: 650
  },
  tableHead: {
    display: 'block',
    float: 'left'
  },
  tableBody: {
    display: 'block',
    float: 'left'
  },
  tableCellinHead: {
    display: 'block',
    width: 200,
    height: '50px',
    paddingRight: '14px',
    border: 'none'
  },
  tableCellinHeadDescription: {
    display: 'flex',
    width: 200,
    height: '100px',
    paddingRight: '14px',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableCellinBody: {
    display: 'block',
    width: 450,
    height: '50px',
    border: 'none',
    textAlign: 'left'
  },
  tableCellinBodyDescription: {
    display: 'flex',
    width: 450,
    height: '100px',
    border: 'none',
    alignItems: 'center',
    textAlign: 'left'
  },
  backButton: {
    margin: theme.spacing(3, 0, 2),
    width: 100
  }
}))

const defaultProps = {
  m: 1,
  borderColor: 'text.primary'
}

const filterAdminPictures = images => {
  return images.filter(image => image.description !== '[BEFORE]')
}

const filterUserPictures = images => {
  return images.filter(image => image.description === '[BEFORE]')
}

const ReportDetail = ({ history, location }) => {
  const [comments, setComments] = useState([])
  const [contactInfo, setContactInfo] = useState('')
  const commentList = generateShapedComments(comments)
  const report = location.state.report
  const pictureFromAdmin = filterAdminPictures(report.attachments)
  const pictureFromUser = filterUserPictures(report.attachments)
  const apiKey = location.state.apiKey
  const classes = useStyles()
  useEffect(() => {
    ;(async () => {
      window.scrollTo(0, 0)
      const fetchedComments = await fetchComments(apiKey, report.id)
      setComments(fetchedComments)
      const contactInfo = await fetchProjectContactInfo(
        report.project.id,
        apiKey
      )
      setContactInfo(contactInfo)
    })()
  }, [apiKey, report.id, report.project.id])
  const handleClick = () => {
    history.goBack()
  }

  return (
    <div>
      <CssBaseline />

      <Grid>
        <Grid
          col={12}
          style={{
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center'
          }}
        >
          <h4 style={{ textAlign: 'center' }}>レポート写真</h4>
        </Grid>
      </Grid>

      <Grid>
        <Grid
          col={12}
          style={{
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center'
          }}
        >
          <Thumbnail pics={pictureFromUser} status="myReport" apiKey={apiKey} />
        </Grid>
      </Grid>

      <Grid>
        <Grid
          col={12}
          style={{
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center'
          }}
        >
          <ReportMap issue={report} />
        </Grid>
      </Grid>

      <Grid>
        <Grid
          col={12}
          style={{
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center'
          }}
        >
          <Box display="flex" justifyContent="center">
            <Box borderTop={1} borderBottom={1} {...defaultProps}>
              <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell
                      align="center"
                      className={classes.tableCellinHead}
                    >
                      No
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinHead}
                    >
                      投稿者
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinHead}
                    >
                      ステータス
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinHead}
                    >
                      タイトル
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinHead}
                    >
                      投稿日付
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinHead}
                    >
                      レポートの種類
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinHeadDescription}
                    >
                      補足説明
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinHead}
                    >
                      現地住所
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  <TableRow key={report.title}>
                    <TableCell
                      align="center"
                      className={classes.tableCellinBody}
                    >
                      {report.id}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinBody}
                    >
                      {report.nickname}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinBody}
                    >
                      {report.status.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinBody}
                    >
                      {report.title}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinBody}
                    >
                      {report.createdOn}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinBody}
                    >
                      {report.tracker.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinBodyDescription}
                    >
                      {report.description !== null
                        ? report.description.length > 200
                          ? report.description.substring(0, 201) + '...'
                          : report.description
                        : null}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.tableCellinBody}
                    >
                      {deriveValueFromCustomFields(
                        report.customFields,
                        '現地住所'
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Title title={report.project.name.replace(/^\d*_/g, '')} />
      <Title title={contactInfo} />
      <Description description="担当者からのコメント" />
      <Grid>
        <Grid
          col={12}
          style={{
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center'
          }}
          direction="column"
        >
          {commentList.map(comment => {
            return <CommentsField comment={comment} />
          })}
        </Grid>
      </Grid>
      <Grid>
        <Grid
          col={12}
          style={{
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center'
          }}
        >
          <Thumbnail pics={pictureFromAdmin} status="fromAdmin" apiKey={apiKey} />
        </Grid>
      </Grid>

      <Grid>
        <Grid
          col={12}
          style={{
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center'
          }}
        >
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            className={classes.backButton}
            onClick={() => {
              handleClick()
            }}
          >
            戻る
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default withRouter(ReportDetail)
