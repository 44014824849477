import { baseUrl } from '../../constants'

export default async function fetchProjectContactInfo(projectId, apiKey) {
  const requestUrl = baseUrl + '/projects/' + `${projectId}` + '.json'
  const project = await fetch(requestUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Redmine-API-Key': apiKey
    }
  })
  const projectJson = await project.json()
  const projectContactInfo = await projectJson.project.custom_fields[0].value
  return projectContactInfo
}
