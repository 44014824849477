import React from 'react'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

const Description = props => {
  const { description } = props
  return (
    <Container component="main" maxWidth="sm">
      <Typography
        component="h6"
        variant="subtitle2"
        align="center"
        color="textPrimary"
        gutterBottom
        style={{ whiteSpace: 'pre-line' }}
      >
        {description}
      </Typography>
    </Container>
  )
}

export default Description
