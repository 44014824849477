import React from 'react'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

const ReportTitle = props => {
  const { title } = props
  return (
    <Container component="main" maxWidth="sm">
      <Typography
        component="h6"
        variant="h6"
        align="left"
        color="textPrimary"
        gutterBottom
      >
        {title}
      </Typography>
    </Container>
  )
}

export default ReportTitle
