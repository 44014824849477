export default function extractPublicProject(projects) {
  const projectList = []
  projects.filter((project) => {
    if (
      project.is_public === true &&
      project.name.startsWith("13") === false &&
      project.name.match('品川') === null &&
      project.name.match('運用') === null
    ) {
      projectList.push(project)
    }
  })
  return projectList
}
