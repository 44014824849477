import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import SmallButton from '../../modules/SmallButton'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1.5),
    textAlign: 'center',
  },
  coloredButton: {
    '&:disabled': {
      color: '#fff',
      backgroundColor: '#3F51B5',
    },
  },
}))

const ReportFilter = (props) => {
  const { selectedIndex, selectReportType, trackerList } = props
  const classes = useStyles()

  return (
    <Fragment>
      <div className={classes.container}>
        <SmallButton
          key={-1}
          index={-1}
          title="全て"
          trackerId={null}
          selected={
            selectedIndex === null || selectedIndex === -1 ? true : false
          }
          selectReportType={selectReportType}
        />
        {trackerList.map((tracker, index) => {
          const isSelected = selectedIndex === index
          return (
            <SmallButton
              key={index}
              index={index}
              title={tracker.name}
              trackerId={tracker.id}
              selected={isSelected}
              selectReportType={selectReportType}
            />
          )
        })}
      </div>
    </Fragment>
  )
}

export default ReportFilter
