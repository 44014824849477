export default function isEmpty(obj) {
  //if (Object.keys(obj).length === 0 && obj.constructor === Object) {
  //  return true
  //}
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false
    }
  }

  return JSON.stringify(obj) === JSON.stringify({})
}
