import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Box
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'

import Thumbnail from '../map/Thumbnail'

const useStyles = makeStyles(theme => ({
  table: {
    width: 650
  },
  tableHead: {
    display: 'block',
    float: 'left'
  },
  tableBody: {
    display: 'block',
    float: 'left'
  },
  tableCellinHead: {
    display: 'block',
    width: 200,
    height: '50px',
    paddingRight: '14px',
    border: 'none'
  },
  tableCellinHeadDescription: {
    display: 'flex',
    width: 200,
    height: '100px',
    paddingRight: '14px',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableCellinBody: {
    display: 'block',
    width: 362,
    height: '50px',
    border: 'none',
    textAlign: 'left'
  },
  tableCellinBodyDescription: {
    display: 'flex',
    width: 362,
    height: '100px',
    border: 'none',
    alignItems: 'center',
    textAlign: 'left'
  },
  backButton: {
    margin: theme.spacing(3, 0, 2),
    width: 100
  }
}))

const defaultProps = {
  m: 1,
  borderColor: 'text.primary'
}

const PostResult = ({ history, location }) => {
  const handleClickBack = () => {
    history.goBack()
  }
  const classes = useStyles()
  let picList = []

  const reportId = location.state.reportId
  const displayName = location.state.displayName
  const status = location.state.status
  const title = location.state.title
  const createdOn = location.state.createdOn
  const trackerName = location.state.trackerName
  const description = location.state.description
  const currentAddress = location.state.currentAddress
  const imageSrc0 = location.state.imageSrc0
  const imageSrc1 = location.state.imageSrc1
  const imageSrc2 = location.state.imageSrc2
  const imageSrc3 = location.state.imageSrc3

  const createData = (
    reportId,
    displayName,
    status,
    title,
    createdOn,
    trackerName,
    description,
    currentAddress
  ) => {
    return {
      reportId,
      displayName,
      status,
      title,
      createdOn,
      trackerName,
      description,
      currentAddress
    }
  }

  const rows = [
    createData(
      reportId,
      displayName,
      status,
      title,
      createdOn,
      trackerName,
      description,
      currentAddress
    )
  ]

  if (imageSrc0) {
    picList.push({ thumbnail_url: imageSrc0 })
  }
  if (imageSrc1) {
    picList.push({ thumbnail_url: imageSrc1 })
  }
  if (imageSrc2) {
    picList.push({ thumbnail_url: imageSrc2 })
  }
  if (imageSrc3) {
    picList.push({ thumbnail_url: imageSrc3 })
  }

  return (
    <div>
      <Grid>
        <Grid>
          <Grid
            col={12}
            style={{
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'center'
            }}
          >
            <h4 style={{ textAlign: 'center' }}>投稿画像</h4>
          </Grid>
        </Grid>

        <Grid>
          <Grid
            col={12}
            style={{
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'center'
            }}
          >
            <Thumbnail pics={picList} status="reportResult" />
          </Grid>
        </Grid>

        <Grid>
          <Grid
            col={12}
            style={{
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'center'
            }}
          >
            <h4>投稿内容詳細</h4>
          </Grid>
        </Grid>

        <Grid>
          <Grid
            col={12}
            style={{
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'center'
            }}
          >
            <Box display="flex" justifyContent="center">
              <Box borderTop={1} borderBottom={1} {...defaultProps}>
                <Table className={classes.table}>
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={classes.tableCellinHead}
                      >
                        No
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellinHead}
                      >
                        投稿者
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellinHead}
                      >
                        ステータス
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellinHead}
                      >
                        タイトル
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellinHead}
                      >
                        投稿日付
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellinHead}
                      >
                        レポートの種類
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellinHeadDescription}
                      >
                        補足説明
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellinHead}
                      >
                        現地住所
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {rows.map(row => (
                      <TableRow key={row.title}>
                        <TableCell
                          align="center"
                          className={classes.tableCellinBody}
                        >
                          {row.reportId}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellinBody}
                        >
                          {row.displayName}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellinBody}
                        >
                          {row.status}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellinBody}
                        >
                          {row.title}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellinBody}
                        >
                          {row.createdOn}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellinBody}
                        >
                          {row.trackerName}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellinBodyDescription}
                        >
                          {row.description}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tableCellinBody}
                        >
                          {row.currentAddress}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          col={12}
          style={{
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            paddingBottom: 50
          }}
        >
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            className={classes.backButton}
            onClick={() => {
              handleClickBack()
            }}
          >
            戻る
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default withRouter(PostResult)
