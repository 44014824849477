import { baseURL, baseGeocoderURL } from './baseURL'

export const baseUrl = baseURL
export const geocoderApiUrl = baseGeocoderURL + '/geocode/json/'
export const reverseGeocoderApiUrl = baseGeocoderURL + '/reversegeocode/json/'

export const handleBirthYearUrl = () => {
  if (
    baseUrl === 'https://dev.admin.mycityreport.jp' ||
    baseUrl === 'https://develop.bizyman.org'
  ) {
    return 227
  } else if (
    baseUrl === 'https://test.admin.mycityreport.jp' ||
    baseUrl === 'https://staging.bizyman.org'
  ) {
    return 222
  } else if (
    baseUrl === 'https://admin.mycityreport.jp' ||
    baseUrl === 'https://public.mycityreport.jp'
  ) {
    return 226
  } else {
    return 226
  }
}

export const USER_CUSTOM_FIELD = {
  ORG_TYPE: { id: 155, name: '個人/団体' },
  MAIN_PROJECT: { id: 220, name: '主な投稿先自治体' },
  PHONE_NUMBER: { id: 116, name: '電話番号' },
  POST_NUMBER: { id: 137, name: '郵便番号' },
  ADDRESS: { id: 117, name: '住所' },
  AGE: { id: 140, name: '年代' },
  GENDER: { id: 143, name: '性別' },
  OCCUPATION: { id: 148, name: '職業' },
  SUPPORTER_REGISTRATION: { id: 1, name: 'サポーター登録' },
  INITIAL_REPORT_STATUS: { id: 182, name: '初期投稿チェック' },
  OLD_REGISTRATION_NUMBER: { id: 224, name: '旧登録番号' },
  BIRTH_YEAR: { id: handleBirthYearUrl(), name: '誕生年' },
}

export const PROJECT_CUSTOM_FIELD = {
  CONTACT_INFORMATION: { id: 161, name: '担当課連絡先' },
  INITIAL_ACCEPTANCE_STATUS: { id: 183, name: '初期受付窓口' },
}

export const ISSUE_CUSTOM_FIELD = {
  CURRENT_ADDRESS: { id: 114, name: '現地住所' },
  AWARD: { id: 209, name: '区名' },
  REPORT_MEANS: { id: 8, name: '通報手段' },
  ROAD_TYPE: { id: 13, name: '道路種別' },
  NICK_NAME: { id: 184, name: 'ニックネーム' },
  REPORTER_ADDRESS: { id: 162, name: '通報者住所' },
  REPORTER_TEL: { id: 126, name: '通報者電話番号' },
  REPORTER_MAIL_ADDRESS: { id: 125, name: '通報者メールアドレス' },
}

export const THEME_CUSTOM_FIELD = {
  START_DATE: { id: 211, name: '開始日' },
  IMAGE: { id: 4, name: '画像' },
}

export const FILTER_CONDITION =
  'set_filter=1&sort=id:desc&f[]=project_id&op[project_id]=!'

export const EXCLUDE_PROJECTS =
  '&v[project_id][]=155&v[project_id][]=156&v[project_id][]=157&v[project_id][]=158&v[project_id][]=159&v[project_id][]=160&v[project_id][]=161&v[project_id][]=162&v[project_id][]=163&v[project_id][]=164&v[project_id][]=165&f[]=&c[]=project&c[]=cf_8'

export const FILTER_STATUS =
  '&c[]=status&c[]=subject&c[]=tracker&c[]=cf_114&c[]=author&c[]=attachments&group_by=&t[]='
