import { baseUrl } from '../../constants'

export default async function postUserToProjectMembership(
  roleId,
  apiKey,
  parentProjectId
) {
  const admissionParams = {
    role_id: roleId
  }
  const headers = {
    'Content-Type': 'application/json',
    'X-Redmine-API-Key': apiKey
  }
  const admissionUrl = `${baseUrl}/projects/${parentProjectId}/admissions.json`

  const admissionResult = await fetch(admissionUrl, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(admissionParams)
  })

  return admissionResult
}
