import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import CopyRight from '../modules/CopyRight'
import appleBadge from '../assets/images/store/apple-badge.svg'
import playBadge from '../assets/images/store/play-badge.svg'
import { getSessionCookie } from '../domains/auth/session'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  footer: {
    padding: theme.spacing(2),
    marginTop: 'auto',
    backgroundColor: 'royalblue',
    color: 'white',
    height: '200px',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
    height: '200px',
  },
  listItemLink: {
    height: '10px',
    marginTop: '3px',
    marginBottom: '3px',
    textAlign: 'center',
  },
  information: {
    fontSize: '0.8em',
    margin: 'auto',
  },
  badge: {
    display: 'inline-block',
    height: '60px',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
}))

export default function Footer() {
  const classes = useStyles()
  const session = getSessionCookie()

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />
  }

  return (
    <div className={classes.root}>
      <footer className={classes.footer}>
        <Container>
          <Grid container>
            <Grid item xs={6}>
              <List>
                <ListItemLink href="/maps" className={classes.listItemLink}>
                  <ListItemText primary="地図表示" />
                </ListItemLink>
                <ListItemLink
                  href="/reportlist"
                  className={classes.listItemLink}
                >
                  <ListItemText primary="レポート一覧" />
                </ListItemLink>
                <ListItemLink
                  href={
                    session.email !== undefined &&
                    session.password !== undefined
                      ? '/postreport'
                      : '/'
                  }
                  className={classes.listItemLink}
                >
                  <ListItemText primary="投稿ページ" />
                </ListItemLink>
              </List>
            </Grid>
            <Grid item xs={6}>
              <div style={{ textAlign: 'center' }}>
                <a href="https://apps.apple.com/jp/app/id1360986117">
                  <img className={classes.badge} src={appleBadge} />
                </a>
              </div>
              <div style={{ textAlign: 'center' }}>
                <a href="https://play.google.com/store/apps/details?id=jp.aigid.mycityreport">
                  <img className={classes.badge} src={playBadge} />
                </a>
              </div>
            </Grid>
          </Grid>
          <CopyRight />
        </Container>
      </footer>
    </div>
  )
}
