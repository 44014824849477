import { baseUrl } from '../../constants'

export default async function fetchMyIssues(authorId, apiKey, offset, limit) {
  const myIssuesApiUrl =
    authorId !== null || authorId !== undefined
      ? baseUrl +
        '/issues.json?offset=' +
        offset +
        '&limit=' +
        limit +
        '&include=attachments,journals&author_id=' +
        authorId +
        '&status_id=*'
      : baseUrl +
        '/issues.json?offset=' +
        offset +
        '&limit=' +
        limit +
        '&include=attachments,journals' +
        '&status_id=*'
  const issues = await fetch(myIssuesApiUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Redmine-API-Key': apiKey
    }
  })
  const issuesJson = await issues.json()
  return issuesJson
}
