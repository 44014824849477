import React from 'react'
import { Map, TileLayer, Marker } from 'react-leaflet'
import Leaflet from 'leaflet'
import 'leaflet/dist/leaflet.css'

import displayMapIcon from '../domains/map/displayMapIcon'

Leaflet.Icon.Default.imagePath =
  '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/'

const ReportMap = (props) => {
  const { issue } = props
  const latitude = JSON.parse(issue.geojson).geometry.coordinates[1]
  const longitude = JSON.parse(issue.geojson).geometry.coordinates[0]
  const zoom = 12
  const minZoom = 3
  const maxZoom = 19.8

  return (
    <div>
      <Map
        center={[latitude, longitude]}
        zoom={zoom}
        minZoom={minZoom}
        maxZoom={maxZoom}
        style={{ height: '500px', width: '500px' }}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          position={[latitude, longitude]}
          title={issue.subject}
          key={'marker_' + issue.id}
          icon={displayMapIcon(issue)}
        />
      </Map>
    </div>
  )
}
export default ReportMap
