import { baseUrl } from '../../constants'

export default async function traceParentProject(projectId) {
  const traceParentProjectUrl = `${baseUrl}/projects/${projectId}.json`
  const traceResult = await fetch(traceParentProjectUrl)
  const traceResultJson = await traceResult.json()
  const parentProject = traceResultJson.project.parent
  if (parentProject === undefined) {
    return projectId
  } else {
    return await traceParentProject(parentProject.id)
  }
}
