import { USER_CUSTOM_FIELD } from '../../constants'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'

import DropDownMenu from '../../modules/DropDownMenu'
import Description from '../../modules/Description'
import { GENDER_LISTS, OCCUPATION_LISTS, BIRTH_YEAR_LISTS } from '../../commons'
import { getAuth } from '../../storage/auth'
import updateUserApi from '../../apis/auth/updateUserInfo'
import { saveAuth } from '../../storage/auth'
import User from '../../models/auth/User'
import deriveIdFromProjectList from '../../domains/project/deriveIdFromProjectList'

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

const Profile = ({ history, projectNames, projects, setCurrentUser }) => {
  const currentUser = getAuth()
  const [birthYear, setBirthYear] = useState(currentUser.birthYear)
  const [gender, setGender] = useState(currentUser.gender)
  const [occupation, setOccupation] = useState(currentUser.occupation)
  const [apiKey, setApiKey] = useState(currentUser.apiKey)
  const [displayName, setDisplayName] = useState(currentUser.displayName)
  const [firstName, setFirstName] = useState(currentUser.firstName)
  const [lastName, setLastName] = useState(currentUser.lastName)
  const [phoneNumber, setPhoneNumber] = useState(currentUser.phoneNumber)
  const [mainProject, setMainProject] = useState(currentUser.mainProject)
  const [address, setAddress] = useState(currentUser.address)

  const updateUserInfo = async e => {
    e.preventDefault()
    const response = await updateUserApi(
      apiKey,
      displayName,
      firstName,
      lastName,
      phoneNumber,
      mainProject,
      address,
      gender,
      occupation,
      birthYear
    )
    if (response.status === 200) {
      const projectId = deriveIdFromProjectList(projects, mainProject)
      const projectIdObject = { mainProjectId: projectId }
      const updatedUser = User({
        user: {
          id: currentUser.id,
          api_key: apiKey,
          custom_fields: [
            {
              id: USER_CUSTOM_FIELD.MAIN_PROJECT.id,
              name: '主な投稿先自治体',
              value: mainProject
            },
            {
              id: USER_CUSTOM_FIELD.PHONE_NUMBER.id,
              name: '電話番号',
              value: phoneNumber
            },
            { id: USER_CUSTOM_FIELD.ADDRESS.id, name: '住所', value: address },
            { id: USER_CUSTOM_FIELD.GENDER.id, name: '性別', value: gender },
            {
              id: USER_CUSTOM_FIELD.OCCUPATION.id,
              name: '職業',
              value: occupation
            },
            {
              id: USER_CUSTOM_FIELD.BIRTH_YEAR.id,
              name: '誕生年',
              value: birthYear
            }
          ],
          displayname: displayName,
          firstname: firstName,
          lastname: lastName
        }
      })
      const user = { ...updatedUser, ...projectIdObject }
      saveAuth(user)
      setCurrentUser(user)
      alert('プロフィールが更新されました')
    }
  }

  const classes = useStyles()

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h6">
          {currentUser.mail}
        </Typography>
        <form className={classes.form} noValidate>
          <Description description="ニックネーム" />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="displayName"
            id="displayName"
            value={displayName}
            onChange={e => setDisplayName(e.target.value)}
          />
          <Description description="姓" />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="firstName"
            id="firstName"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />
          <Description description="名" />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="lastName"
            id="lastName"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
          <Description description="電話番号" />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="phone"
            name="phone"
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
          />
          <Description description="住所" />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="address"
            id="address"
            value={address}
            onChange={e => setAddress(e.target.value)}
          />
          <Box display="flex" flexDirection="row" justifyContent="center">
            <DropDownMenu
              id="mainProject"
              title="主な投稿先"
              listItems={projectNames}
              selectedValue={mainProject}
              setValue={setMainProject}
            />
            <DropDownMenu
              id="birthYear"
              title="誕生年を更新"
              listItems={BIRTH_YEAR_LISTS}
              selectedValue={birthYear}
              setValue={setBirthYear}
            />
            <DropDownMenu
              id="gender"
              title="性別を更新"
              listItems={GENDER_LISTS}
              selectedValue={gender}
              setValue={setGender}
            />
            <DropDownMenu
              id="occupation"
              title="職業を更新"
              listItems={OCCUPATION_LISTS}
              selectedValue={occupation}
              setValue={setOccupation}
            />
          </Box>
        </form>
        <Button variant="outlined" color="primary" onClick={updateUserInfo}>
          変更する
        </Button>
      </div>
    </Container>
  )
}

export default withRouter(Profile)
