import React, { useState, useEffect } from 'react'
import { withRouter, Link as RouterLink } from 'react-router-dom'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { List, ListItem, ListItemText } from '@material-ui/core';


import User from '../../models/auth/User'
import signInApi from '../../apis/auth/signIn'
import { setSessionCookie, getSessionCookie } from '../../domains/auth/session'
import { saveAuth } from '../../storage/auth'
import { baseUrl } from '../../constants'
import deriveIdFromProjectList from '../../domains/project/deriveIdFromProjectList'

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))
const SignIn = ({ history, projectLists }) => {
  useEffect(() => {
    const session = getSessionCookie()
    if (session.email !== undefined && session.password !== undefined) {
      history.push({ pathname: '/reportlist' })
    }
  })

  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrMsg] = useState('')

  const onSubmit = async () => {
    const response = await signInApi(email, password)
    if (response !== undefined) {
      const user = User(response)
      const projectId = deriveIdFromProjectList(projectLists, user.mainProject)
      const projectIdObject = { mainProjectId: projectId }
      const updatedUser = { ...user, ...projectIdObject }
      saveAuth(updatedUser)
      setSessionCookie({ email, password })
      history.push({ pathname: '/reportlist' })
    } else {
      setErrMsg('IDまたはパスワードが誤っています')
    }
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h6">
          Log in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="次回から入力を省略"
          />
          <div style={{ color: 'red' }}>{errorMessage}</div>
          <Button
            fullWidth
            variant="outlined" // outlined, contained
            color="primary"
            className={classes.submit}
            onClick={onSubmit}
          >
            ログイン
          </Button>
          <List> 
            <ListItem>
                パスワードを忘れた方や、ちばレポから移行された方は
              <Link variant="body2" href={baseUrl + '/account/lost_password'}>
                こちら
              </Link>
            </ListItem>
            <ListItem>
                新規登録の方は
              <Link component={RouterLink} to="/SignUp">
                こちら
              </Link>
            </ListItem>
            <ListItem>
              <ListItemText
                primary={<Typography style={{fontSize: '0.875rem'}}>My City Reportは、スマートフォンアプリをメインで提供するサービスのため、
                  Web版で利用する場合、ブラウザによって動作に不具合が生じたり、一部利用できない機能があります。すべての機能をご利用の場合は、
                  <Link href={'https://www.mycityreport.jp/'}>こちら</Link>からスマートフォンアプリをインストールしてください。</Typography>}
                secondary={'※現在対応しているブラウザはChromeまたはFirefoxのみです。（Internet Explorerでは利用できません。Edgeは一部機能に制限があります。）'}
              >
              </ListItemText>
            </ListItem>
          </List>
        </form>
      </div>
    </Container>
  )
}

export default withRouter(SignIn)
