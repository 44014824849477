import {
  baseUrl,
  FILTER_CONDITION,
  EXCLUDE_PROJECTS,
  FILTER_STATUS,
} from '../../constants'

export default function generateEndpointUrl(
  projectId,
  trackerId,
  offset,
  limit,
  isProjectFilter,
  isTrackerFilter
) {
  const include = 'attachments,journals'
  let appendUrlCondition
  appendUrlCondition =
    isProjectFilter || isTrackerFilter
      ? '/issues.json?' +
        '&offset=' +
        offset +
        '&limit=' +
        limit +
        '&include=' +
        include +
        `&status_id=*`
      : '/issues.json?' +
        FILTER_CONDITION +
        EXCLUDE_PROJECTS +
        FILTER_STATUS +
        '&offset=' +
        offset +
        '&limit=' +
        limit +
        '&include=' +
        include +
        `&status_id=*`

  const trackerCondition = '&tracker_id=' + trackerId
  const projectCondition = '&project_id=' + projectId
  const endPointUrl =
    baseUrl +
    appendUrlCondition +
    `${trackerId ? trackerCondition : ''}` +
    `${projectId ? projectCondition : ''}`
  return endPointUrl
}
