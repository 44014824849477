const generateReportImages = (imageFiles, tokens, trackerName) => {
  const images = imageFiles.map((image, i) => {
    return {
      token: tokens[i],
      filename: image.name,
      description:
        i === 0
          ? '[BEFORE]'
          : trackerName !== 'かいけつレポート'
          ? '[BEFORE]'
          : '[AFTER]',
      content_type: image.type
    }
  })
  return images
}

export default generateReportImages
