import isEmpty from './isEmpty'

export default function isAvailable(obj) {
  if (isEmpty(obj)) {
    return false
  } else if (obj === undefined) {
    return false
  } else if (obj === null) {
    return false
  } else if (obj.length === 0) {
    return false
  } else {
    return true
  }
}
