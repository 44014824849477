import React, { useEffect, useState } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Cookies from 'js-cookie'

import SignIn from './auth/SignIn'
import SignUp from './auth/SignUp'
import Profile from './auth/Profile'
import EmergContact from './auth/EmergContact'
import Maps from './map/Maps'
import ReportDetail from './report/ReportDetail'
import PostReport from './report/PostReport'
import PostResult from './report/PostResult'
import ReportList from './report/ReportList'
import { SessionContext, getSessionCookie } from '../domains/auth/session'
import Header from '../modules/Header'
import Footer from '../modules/Footer'
import fetchAllIssues from '../apis/report/fetchAllIssues'
import fetchMyIssues from '../apis/report/fetchMyIssues'
import fetchComments from '../apis/report/fetchComments'
import fetchProject from '../apis/project/fetchProject'
import extractPublicProject from '../domains/project/extractPublicProject'
import extractParentProject from '../domains/project/extractParentProject'
import extractLatestPublicIssues from '../domains/report/extractLatestPublicIssues'
import sortIssuesByUpdatedOn from '../domains/report/sortIssuesByUpdatedOn'
import isAvailable from '../lib/isAvailable'
import genProjectNameList from '../models/project/genProjectNameList'
import extractMainProjects from '../models/project/extractMainProjects'
import { getAuth, removeAuth } from '../storage/auth'
import { generateShapedIssues } from '../models/report'
import fetchTrackers from '../apis/report/fetchTrackers'
import excludeTokyoRegion from '../domains/report/excludeTokyoRegion'
import handleAllProjects from '../apis/project/handleAllProjects'
import extractAvblTrackers from '../domains/report/extractAvblTrackers'
import Maintainance from './error/Maintainance'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: '100vh',
    position: 'relative',
    paddingBottom: '200px',
    boxSizing: 'border-box',
  },
}))

const App = () => {
  const [projects, setProjects] = useState([])
  const [projectNames, setProjectNameList] = useState([])
  const [session, setSession] = useState(getSessionCookie())
  const [allIssues, setAllIssues] = useState([])
  const [myIssues, setMyIssues] = useState([])
  const [isAllReport, setAllReport] = useState(true)
  const [isMyReport, setMyReport] = useState(false)
  const [isProjectFilter, setProjectFilter] = useState(false)
  const [isTrackerFilter, setTrackerFilter] = useState(false)
  const [apiKey, setApiKey] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState('')
  const [trackerId, setTrackerId] = useState(null)
  const [projectId, setProjectId] = useState(() => {
    let projectId;
    if (currentUser.mainProject !== undefined) {
      projectId = currentUser.mainProject
    } else {
      projectId = null
    }
    return projectId
  })
  const [trackerList, setTrackerList] = useState([])
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(25)
  const [myOffset, setMyOffset] = useState(25)
  const [myLimit, setMyLimit] = useState(25)
  const [authUser, setAuthUser] = useState(null)
  const [selectedTrackerIndex, setSelectedTrackerIndex] = useState(null)
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(null)
  const [mainProject, setMainProject] = useState([])

  async function handleAllIssues(
    authUser,
    projectId,
    trackerId,
    offset,
    limit,
    isProjectFilter,
    isTrackerFilter
  ) {
    const apiKey = isAvailable(authUser)
      ? authUser.apiKey
      : '6f22ff2d3c20d6e6a397e45a6db928487e0cb31e'
    setApiKey(apiKey)
    const allIssues = await fetchAllIssues(
      apiKey,
      projectId,
      trackerId,
      offset,
      limit,
      isProjectFilter,
      isTrackerFilter
    )
    const excludedIssues = excludeTokyoRegion(allIssues.issues)
    const sortedIssues = sortIssuesByUpdatedOn(excludedIssues)
    const publicIssues = await extractLatestPublicIssues(sortedIssues)
    const shapedAllIssues = await generateShapedIssues(publicIssues)
    setAllIssues(shapedAllIssues)
  }

  async function handleMyIssues(authUser, offset, limit) {
    if (!isAvailable(authUser)) return
    const apiKey = authUser.apiKey
    const authorId = authUser.id
    const isSupporter = authUser.isSupporter
    const myIssues = await fetchMyIssues(authorId, apiKey, offset, limit)
    const shapedMyIssues = await generateShapedIssues(myIssues.issues)
    if (!isSupporter) {
      const myFilteredIssues = shapedMyIssues.filter(
        (issue) =>
          issue.status.name !== '協働案件化' &&
          issue.status.name !== '募集中' &&
          issue.status.name !== '協働実施中'
      )
      const sortedMyFilteredIssues = sortIssuesByUpdatedOn(myFilteredIssues)
      setMyIssues(sortedMyFilteredIssues)
    } else {
      setMyIssues(shapedMyIssues)
    }
    setCurrentUser(authUser)
  }

  async function handleComments(apiKey, reportId) {
    const comments = await fetchComments(apiKey, reportId)
    return comments
  }

  const deriveMainProjects = async () => {
    const allProjects = await handleAllProjects(projects, offset, limit)
    const publicProjects = extractPublicProject(allProjects)
    const parentProjects = extractParentProject(publicProjects)
    const mainProjects = extractMainProjects(parentProjects)
    return mainProjects
  }

  const selectAllReport = () => {
    setAllReport(true)
    setMyReport(false)
    setProjectFilter(false)
    setTrackerFilter(false)
  }

  const selectMyReport = () => {
    setAllReport(false)
    setMyReport(true)
    setProjectFilter(false)
    setTrackerFilter(false)
  }

  const selectReportType = (trackerId, index) => {
    setIsLoading(true)
    if (trackerId === null) {
      setTrackerFilter(false)
    } else {
      setTrackerFilter(true)
    }
    setTrackerId(trackerId)
    setSelectedTrackerIndex(index)
  }

  const selectProject = (projectId, index) => {
    setIsLoading(true)
    if (projectId === null) {
      setProjectFilter(false)
    } else {
      setProjectFilter(true)
    }
    setProjectId(projectId)
    setSelectedProjectIndex(index)
  }

  const removeSessionAndAuth = (history) => {
    Cookies.remove('session')
    removeAuth(currentUser)
    history.push({ pathname: '/' })
  }

  useEffect(() => {
    ;(async () => {
      const authUser = getAuth() || {}
      setAuthUser(authUser)
      await handleMyIssues(authUser, 0, 25)
      const mainProjects = await deriveMainProjects()
      setProjects(mainProjects)
      await handleAllIssues(
        authUser,
        projectId,
        trackerId,
        0,
        25,
        isProjectFilter,
        isTrackerFilter
      )
      const projectNames = await genProjectNameList(mainProjects)
      setProjectNameList(projectNames)
      if (projectId !== null) {
        const trackers = await fetchTrackers(projectId)
        const avblTrackers = extractAvblTrackers(trackers)
        setTrackerList(avblTrackers)
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    })()
  }, [projectId, trackerId])

  useEffect(() => {
    ;(async () => {
      const mainProject = await fetchProject(currentUser.mainProjectId)
      setMainProject(mainProject)
    })()
  }, [currentUser.mainProjectId])

  const classes = useStyles()

  return (
    <SessionContext.Provider value={session}>
      <BrowserRouter>
        <div className={classes.wrapper}>
          <Header removeSessionAndAuth={removeSessionAndAuth} />
          <Switch>
            {/** メンテナンスモードに切り替える際は以下のコメントアウトを外す
            <Route
              exact
              path={'/'}
              render={() => <Maintainance />}
            />
            <Route>
              <Redirect to="/" />
            </Route>
            **/}
            <Route
              exact
              path={'/'}
              render={() => <SignIn projectLists={projects} />}
            />
            <Route
              exact
              path={'/signup'}
              render={() => <SignUp projectLists={projects} />}
            />
            <Route
              exact
              path={'/maps'}
              render={() => (
                <Maps
                  issues={allIssues}
                  currentUser={currentUser}
                  selectedProjectIndex={selectedProjectIndex}
                  selectProject={selectProject}
                  projectList={projects}
                />
              )}
            />
            <Route path={'/reportdetail'} render={() => <ReportDetail />} />
            <Route path={'/postreport'} render={() => <PostReport />} />
            <Route path={'/postresult'} component={() => <PostResult />} />
            <Route
              path={'/reportlist'}
              component={() => (
                <ReportList
                  allIssues={isAllReport ? allIssues : myIssues}
                  selectAllReport={() => selectAllReport()}
                  selectMyReport={() => selectMyReport()}
                  selectReportType={selectReportType}
                  selectProject={selectProject}
                  selectedTrackerIndex={selectedTrackerIndex}
                  selectedProjectIndex={selectedProjectIndex}
                  apiKey={apiKey}
                  handleComments={() => handleComments()}
                  isLoading={isLoading}
                  isAllReport={isAllReport}
                  trackerList={trackerList}
                  trackerId={trackerId}
                  offset={offset}
                  myOffset={myOffset}
                  limit={limit}
                  isProjectFilter={isProjectFilter}
                  isTrackerFilter={isTrackerFilter}
                  projectList={projects}
                  projectId={projectId}
                  setIsLoading={setIsLoading}
                />
              )}
            />
            <Route
              path={'/profile'}
              component={() => (
                <Profile
                  projectNames={projectNames}
                  projects={projects}
                  setCurrentUser={setCurrentUser}
                />
              )}
            />
            <Route
              path={'/emergcontact'}
              component={() => (
                <EmergContact mainProject={mainProject} />
              )}
            />
            <Route
              path={'/emergcontact'}
              component={() => (
                <EmergContact mainProject={mainProject} />
              )}
            />
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    </SessionContext.Provider>
  )
}

export default App
