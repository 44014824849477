export default function deriveValueFromCustomField(customFields, name) {
  const fieldValue = customFields.filter(field => {
    if (field.name === name) {
      return field.value
    }
  })
  const value =
    fieldValue.length !== 0
      ? fieldValue[0].name !== 'サポーター登録' &&
        fieldValue[0].name !== '初期投稿チェック'
        ? fieldValue[0].value
        : Boolean(fieldValue[0].value)
      : null
  return value
}
