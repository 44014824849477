import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { Select } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  },
  formControl: {
    minWidth: 380,
    marginTop: 15,
    marginBottom: 10
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))

const TrackerList = ({
  title,
  emptyMessage,
  projectLists,
  selectedValue,
  handleChange
}) => {
  const classes = useStyles()
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputLabel} htmlFor="outlined-tracker-list">
          {title + '*'}
        </InputLabel>
        <Select
          value={selectedValue}
          onChange={handleChange}
          input={
            <OutlinedInput
              labelWidth={labelWidth}
              name="TrackerList"
              id="outlined-tracker-list"
            />
          }
        >
          {
            (projectLists && projectLists.length > 0) ?
              projectLists.map(project =>
                project.trackers
                  .filter(tracker => !tracker.name.includes('協働案件'))
                  .filter(tracker => !tracker.name.includes('完了レポート'))
                  .filter(tracker => !tracker.name.includes('テーマレポート'))
                  .filter(tracker => tracker.name !== '下水道')
                  .filter(tracker => tracker.name !== '自転車')
                  .map((tracker, i) => (
                    <MenuItem key={i} value={tracker}>
                      {tracker.name}
                    </MenuItem>
                  ))
              ) : <MenuItem value={''}>{emptyMessage}</MenuItem>
          }
        </Select>
      </FormControl>
    </div>
  )
}

export default TrackerList
