import { baseUrl } from '../../constants'

export default async function postUserAuthData(formData) {
  const requestUrl = baseUrl + '/account/register'
  const postResponse = await fetch(requestUrl, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    redirect: 'manual',
    body: formData
  })

  return postResponse
}
