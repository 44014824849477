import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    marginTop: 10,
    marginBottom: 10
  },
  coloredButton: {
    '&:disabled': {
      color: '#fff',
      backgroundColor: '#3F51B5'
    }
  }
}))

const ToggleButtons = props => {
  const classes = useStyles()
  const {
    leftTitle,
    rightTitle,
    selectAllReport,
    selectMyReport,
    isAllReport
  } = props
  return (
    <Grid item xs={12}>
      <ButtonGroup fullWidth className={classes.buttonGroup}>
        {isAllReport ? (
          <Button disabled className={classes.coloredButton}>
            {leftTitle}
          </Button>
        ) : (
          <Button onClick={() => selectAllReport()}>{leftTitle}</Button>
        )}
        {isAllReport ? (
          <Button onClick={() => selectMyReport()}>{rightTitle}</Button>
        ) : (
          <Button disabled className={classes.coloredButton}>
            {rightTitle}
          </Button>
        )}
      </ButtonGroup>
    </Grid>
  )
}

export default ToggleButtons
