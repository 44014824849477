import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  progress: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: '-webkit-translate(-50%, -50%)',
    zIndex: 1
  }
}))

const CircularIndicator = () => {
  const classes = useStyles()

  return (
    <div>
      <CircularProgress className={classes.progress} color="secondary" />
    </div>
  )
}

export default CircularIndicator
