import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    marginTop: '15px',
    marginBottom: '7px'
  }
}))

const CommentsField = props => {
  const { comment } = props
  const classes = useStyles()
  return (
    <Container component="main" maxWidth="sm">
      <FormControl className={classes.formControl} variant="outlined">
        <InputLabel htmlFor="component-outlined">
          {comment.createdOn}
        </InputLabel>
        <OutlinedInput
          id="component-outlined"
          value={comment.description}
          multiline={true}
        />
      </FormControl>
    </Container>
  )
}

export default CommentsField
