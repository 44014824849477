import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import changeIconByTracker from '../domains/report/changeIconByTracker'

const useStyles = makeStyles({
  avatar: {
    height: 23,
    width: 23,
    color: '#fff',
    backgroundColor: props => props.statusColor
  }
})

const ImageAvatar = props => {
  const { trackerName } = props
  const classes = useStyles(props)

  return (
    <Avatar src={changeIconByTracker(trackerName)} className={classes.avatar} />
  )
}

export default ImageAvatar
