import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal, Backdrop, Fade } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  modalImage:{
    height: "36vw"
  }
}))

const Thumbnail = props => {
  const classes = useStyles()
  const { pics, status, apiKey } = props
  const api = status === "myReport" || status === "fromAdmin" ? '?key=' + apiKey : ""
  const [open, setOpen] = React.useState(false)
  const [preview, setPreview] = React.useState('')

  const handleOpen = url => {
    setPreview(url)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (Array.isArray(pics) && pics.length !== 0) {
    return (
      <div style={{ display: 'float' }}>
        {pics.map((pic, i) => (
          <img
            key={i}
            src={pic.thumbnail_url + api}
            style={{ width: '200px', height: '200px', marginRight: '20px' }}
            alt="pinPhoto"
            onClick={() => {
              handleOpen(pic.thumbnail_url + api)
            }}
          />
        ))}
        <Modal
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <img src={preview} className={classes.modalImage} alt="pinPhoto" />
            </div>
          </Fade>
        </Modal>
      </div>
    )
  } else {
    return <div>画像・動画はありません</div>
  }
}

export default Thumbnail
