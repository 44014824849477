import fetchProjectsApi from '../../apis/project/fetchProjects'
import extractPublicProject from '../../domains/project/extractPublicProject'
import extractProjectName from '../../domains/project/extractProjectName'
import extractParentProject from '../../domains/project/extractParentProject'
import excludeUnavailableProjects from '../../domains/project/excludeUnavailableProjects'

export default async function genProjectNameList(projectList) {
  const projectNames = extractProjectName(projectList)
  return projectNames
}
