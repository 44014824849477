export default function deriveFieldIdOfBirthYear(baseUrl) {
  if (baseUrl === 'https://staging.bizyman.org') {
    return 222
  } else if (baseUrl === 'https://develop.bizyman.org') {
    return 227
  } else if (baseUrl === 'https://public.mycityreport.jp') {
    return 226
  } else {
    return null
  }
}
