import {
  INPROGRESS_STATUS,
  FINISHED_STATUS,
  ESCALATION_STATUS,
} from '../../commons'

export default function convertStatusByName(statusName) {
  if (INPROGRESS_STATUS.includes(statusName)) {
    statusName = '対応中'
    return statusName
  } else if (FINISHED_STATUS.includes(statusName)) {
    statusName = '対応済'
    return statusName
  } else if (ESCALATION_STATUS.includes(statusName)) {
    statusName = '対応済（外部機関へ取次ぎ）'
    return statusName
  } else {
    return statusName
  }
}
