import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const AlertModal = props => {
  //TODO: リファクタリングする。https://speakerdeck.com/sonatard/xuan-yan-de-ui?slide=65
  const {
    roleId,
    parentProjectId,
    apiKey,
    postUserToProjectMembership,
    isOpen
  } = props
  const [open, setOpen] = useState(isOpen)
  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  const handleAcceptance = (roleId, apiKey, parentProjectId) => {
    postUserToProjectMembership(roleId, apiKey, parentProjectId)
    setOpen(false)
  }
  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'お知らせ'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            この自治体への初めての投稿です。この自治体の利用規約に基づきユーザー情報を提供します。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id="handle-close"
            onClick={() => handleAcceptance(roleId, apiKey, parentProjectId)}
            color="primary"
          >
            OK
          </Button>
          <Button
            id="handle-close"
            onClick={() => handleCancel()}
            color="primary"
            autoFocus
          >
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AlertModal
