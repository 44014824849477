import Cookies from 'js-cookie'
import { createContext } from 'react'

export const setSessionCookie = session => {
  Cookies.remove('session')
  Cookies.set('session', session, { expires: 30 })
}

export const getSessionCookie = () => {
  const sessionCookie = Cookies.get('session')
  if (sessionCookie === undefined) {
    return {}
  } else {
    return JSON.parse(sessionCookie)
  }
}

export const SessionContext = createContext(getSessionCookie())
