import React from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

const FormButton = props => {
  const { description, handleOnClick } = props
  const classes = useStyles()
  return (
    <Button
      size="large"
      variant="outlined" // outlined, contained
      color="primary"
      className={classes.submit}
      onClick={() => handleOnClick()}
    >
      {description}
    </Button>
  )
}

export default FormButton
