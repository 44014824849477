import getRegisterReponse from './getRegisterResponse'
import postUserAuthData from './postUserAuthData'
import extractAuthToken from '../../domains/auth/extractAuthToken'
import genFormDataFromUserData from '../../models/auth/genFormDataFromUserData'

export default async function signUpApi(
  email,
  password,
  firstName,
  lastName,
  nickName,
  isSupporter,
  phoneNumber,
  addressNumber,
  project,
  birthYear,
  gender,
  occupation,
  address,
  hasGroupSelected
) {
  const registerResponse = await getRegisterReponse()
  const authenticityToken = await extractAuthToken(registerResponse)
  const formData = genFormDataFromUserData(
    authenticityToken,
    email,
    password,
    firstName,
    lastName,
    nickName,
    isSupporter,
    phoneNumber,
    addressNumber,
    project,
    birthYear,
    gender,
    occupation,
    address,
    hasGroupSelected,
    password
  )
  const signUpResponse = await postUserAuthData(formData)
  return signUpResponse
}
