const key = 'auth'

export function saveAuth(auth) {
  window.localStorage.setItem(key, JSON.stringify(auth))
}

export function getAuth() {
  return JSON.parse(window.localStorage.getItem(key))
}

export function removeAuth() {
  window.localStorage.removeItem(key)
}
