import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
const useStyles = makeStyles(theme => ({
  container: {
    width: 150,
    height: 150,
    marginTop: 20
  },
  root: {
    flexGrow: 1,
    width: '100%',
    height: '100%'
  },
  content: {
    width: '100%',
    height: '100%'
  },
  image: {
    width: '100%'
  },
  button: {
    marginTop: 0
  }
}))

const ReportImage = props => {
  const classes = useStyles()
  const { imageSrc, onClickDelete } = props
  return imageSrc !== '' ? (
    <div className={classes.container}>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} className={classes.content}>
          <Grid
            container
            justify="center"
            spacing={2}
            className={classes.content}
          >
            <Grid item xs={10} className={classes.content}>
              <img src={imageSrc} className={classes.image} />
            </Grid>
            <Grid item xs={2}>
              <button onClick={onClickDelete} className={classes.button}>
                ×
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  ) : null
}

export default ReportImage
