import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}))

export default function ReportTextFields(props) {
  const { title, description } = props
  const classes = useStyles()

  return (
    <TextField
      id="outlined-name"
      label={title}
      className={classes.textField}
      value={description}
      description={description}
      margin="normal"
      variant="outlined"
    />
  )
}
