import { baseUrl } from '../../constants'

export default async function fetchComments(apiKey, reportId) {
  const requestUrl =
    baseUrl + '/issues/' + reportId + '.json?include=attachments,journals'
  const issue = await fetch(requestUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Redmine-API-Key': apiKey
    }
  })
  const issueJson = await issue.json()
  const comments = issueJson.issue.journals.filter(
    journal => journal.notes !== null
  )
  return comments
}
