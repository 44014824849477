import { reverseGeocoderApiUrl } from '../../constants'

export default async function deriveCurrentAddressFromCoords(latlng) {
  const latitude = latlng.lat
  const longitude = latlng.lng
  const requestUrl =
    reverseGeocoderApiUrl + longitude + ',' + latitude + ',1000'
  const geolocatedAddress = await fetch(requestUrl)
  const geolocatedAddressJson = await geolocatedAddress.json()
  const currentAddress = geolocatedAddressJson.result.address
  return currentAddress
}
