const NEW = ['新規', '受付済']
const IN_PROGRESS = [
  '調査中',
  '募集中',
  '対応予定',
  '経過観察',
  '協働案件化',
  '市外取次',
  '対応中',
]
const END = ['終了', '却下', '外部機関へ取次', '対応済', 'サポーター活動へ移行']

export default function generateImageStatusFromStatusName(statusName) {
  let imageStatus
  if (NEW.includes(statusName)) {
    imageStatus = 'new'
  } else if (IN_PROGRESS.includes(statusName)) {
    imageStatus = 'progress'
  } else if (END.includes(statusName)) {
    imageStatus = 'end'
  }
  return imageStatus
}
