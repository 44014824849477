import L from 'leaflet'
import returnIconByStatus from '../../domains/map/returnIconByStatus'

const displayMapIcon = issue =>
  new L.icon({
    iconUrl: returnIconByStatus(issue),
    iconRetinaUrl: returnIconByStatus(issue),
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
    className: 'leaflet-div-icon'
  })

export default displayMapIcon
