import { USER_CUSTOM_FIELD } from '../../constants/index'

export default function genFormDataFromUserData(
  authenticityToken,
  mail,
  password,
  firstName,
  lastName,
  nickName,
  isSupporter,
  phoneNumber,
  postNumber,
  pickerMainProjectSelection,
  birthYear,
  pickerSexSelection,
  pickerOccupationSelection,
  address,
  hasGroupSelected,
  confirmPassword
) {
  let formData = new FormData()
  formData.append('authenticity_token', authenticityToken)
  formData.append('user[login]', mail)
  formData.append('user[password]', password)
  formData.append('user[password_confirmation]', confirmPassword)
  formData.append('user[firstname]', firstName)
  formData.append('user[lastname]', lastName)
  formData.append('user[mail]', mail)
  formData.append('user[language]', 'ja')
  formData.append('user[displayname]', nickName)
  formData.append(
    'user[custom_field_values][' +
      USER_CUSTOM_FIELD.SUPPORTER_REGISTRATION.id +
      ']',
    isSupporter ? '1' : '0'
  )
  formData.append(
    'user[custom_field_values][' + USER_CUSTOM_FIELD.PHONE_NUMBER.id + ']',
    phoneNumber
  )
  formData.append(
    'user[custom_field_values][' + USER_CUSTOM_FIELD.POST_NUMBER.id + ']',
    postNumber ? postNumber : ''
  )
  formData.append(
    'user[custom_field_values][' + USER_CUSTOM_FIELD.MAIN_PROJECT.id + ']',
    pickerMainProjectSelection
  )
  formData.append(
    'user[custom_field_values][' + USER_CUSTOM_FIELD.BIRTH_YEAR.id + ']',
    birthYear
  )
  formData.append(
    'user[custom_field_values][' + USER_CUSTOM_FIELD.GENDER.id + ']',
    pickerSexSelection ? pickerSexSelection : ''
  )
  formData.append(
    'user[custom_field_values][' + USER_CUSTOM_FIELD.OCCUPATION.id + ']',
    pickerOccupationSelection ? pickerOccupationSelection : ''
  )
  formData.append(
    'user[custom_field_values][' + USER_CUSTOM_FIELD.ADDRESS.id + ']',
    address ? address : ''
  )
  formData.append(
    'user[custom_field_values][' + USER_CUSTOM_FIELD.ORG_TYPE.id + ']',
    hasGroupSelected ? '団体' : '個人'
  )
  return formData
}
