import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { Select } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  },
  formControl: {
    minWidth: 380,
    marginTop: 15,
    marginBottom: 10
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))

const FadeMenu = ({ title, listItems, selectedValue, handleChange }) => {
  const classes = useStyles()
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
          {title + '*'}
        </InputLabel>
        <Select
          value={selectedValue}
          onChange={handleChange}
          input={
            <OutlinedInput
              labelWidth={labelWidth}
              name="fademenu"
              id="outlined-fade-menu"
            />
          }
        >
          {listItems !== null
            ? listItems.map((item, i) => (
                <MenuItem key={i} value={item}>
                  {item.name}
                </MenuItem>
              ))
            : null}
        </Select>
      </FormControl>
    </div>
  )
}

export default FadeMenu
