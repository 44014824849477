import deriveValueFromCustomFields from '../../lib/deriveValueFromCustomFields'

const User = response => ({
  id: response.user.id,
  apiKey: response.user.api_key,
  login: response.user.login,
  mail: response.user.login,
  firstName: response.user.firstname,
  lastName: response.user.lastname,
  displayName: response.user.displayname,
  addressNumber: deriveValueFromCustomFields(
    response.user.custom_fields,
    '郵便番号'
  ),
  phoneNumber: deriveValueFromCustomFields(
    response.user.custom_fields,
    '電話番号'
  ),
  isSupporter: deriveValueFromCustomFields(
    response.user.custom_fields,
    'サポーター登録'
  ),
  occupation: deriveValueFromCustomFields(response.user.custom_fields, '職業'),
  birthYear: deriveValueFromCustomFields(response.user.custom_fields, '誕生年'),
  address: deriveValueFromCustomFields(response.user.custom_fields, '住所'),
  mainProject: deriveValueFromCustomFields(
    response.user.custom_fields,
    '主な投稿先自治体'
  ),
  gender: deriveValueFromCustomFields(response.user.custom_fields, '性別')
})

export default User
