import fetchProjects from './fetchProjects'

export default async function handleAllProjects(projects, offset, limit) {
  const response = await fetchProjects(offset, limit)
  const projectList = [...projects, ...response.projects]
  const totalProjectCount = response.total_count
  const updatedOffset = offset + limit
  if (totalProjectCount > updatedOffset) {
    return handleAllProjects(projectList, updatedOffset, limit)
  } else {
    return projectList
  }
}
