import { baseUrl } from '../../constants'

export default async function fetchTrackers(projectId) {
  if (!projectId) {
    return []
  }
  const requestUrl = `${baseUrl}/projects/${projectId}${`.json?include=trackers`}`
  const projectItems = await fetch(requestUrl)
  const projectItemsJson = await projectItems.json()
  const trackersList = projectItemsJson.project.trackers
  return trackersList
}
